import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { withNamespaces } from "react-i18next";
import OtpInput from "react-otp-input";
import { Card, Accordion, Button } from "react-bootstrap";
import moment from "moment";
import poster2 from "@assets/pictures/banner.png";
import backArrow from "@assets/pictures/svgs/back-white-arrow.svg";
import greyArrowDown from "@assets/pictures/svgs/grey-arrow-down.svg";
import { ReactComponent as ArrowBack } from "@assets/pictures/svgs/arrow-back.svg";
import circles from "@assets/pictures/svgs/circles.svg";
import { ReactComponent as ArrowBackActive } from "@assets/pictures/svgs/arrow-back-active.svg";
import { setReservationID } from "../store/seatLayout/seatLayoutActions";
import {
  cinemaBookSeats,
  bookSeats,
  fetchScheduleDetails,
  onSuccessStripeToken,
  fetchStripePublisherKey,
  fetchReservationDetails,
  fetchReservationDetailsBrij,
  onSuccessStripeTokenBrij,
  makeSaferPayPayment,
  fetchActivePaymentModes,
  makeOfferPaymentBooking,
  unpaidBooking,
  makeFullCashCardpayment,
} from "../store/movies/movieActions";
import { seatTypesSelector } from "../store/movies/movieSelector";
import {
  clearSelectedVoucher,
  validateVoucherCode,
} from "../store/promotions/promotionsActions";
import {
  login as loginAction,
  register,
  guestRegister,
  loginViaOtp,
  numberRegex,
  forgotPassword,
  startTimer,
  resetTimer,
} from "../store/auth/authActions";
import LoginPopup from "./partials/popups/LoginPopup";
import LoginOtp from "./partials/LoginOtp";
import ForgotPasswordOtp from "./partials/ForgotPasswordOtp";
import greenCheckCircle from "@assets/pictures/svgs/green-check-circle.svg";
import useIsMobile from "../customHooks/useIsMobile";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CountryCodeDropdown from "./partials/CountryCodeDropdown";
import { currencyFormatter } from "../helper/currencyFormatter";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";
import _ from "lodash";
import showPasswordIcon from "@assets/pictures/svgs/show-password.svg";
import hidePasswordIcon from "@assets/pictures/svgs/hide-password.svg";
import paymentSuccessIcon from "@assets/pictures/svgs/payment_success.svg";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import GoBack from "@components/partials/GoBack";
import Spinner from "./partials/Spinner";
import { fetchCashCardDetailsFromCardNumberPin } from "../store/cashCard/cashCardActions";


const PaymentFlowApp = ({ t }) => {
  const isMobile = useIsMobile();
  const [loginIconBool, setLoginIconBool] = useState(false);
  const [login, setLogin] = useState(false);
  const showBookingID = useSelector((state) => state.movies.showBookingID);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const [open, setOpen] = useState([1, 0, 0, 0, 0, 0, 0, 0]);
  const [otp, setOtp] = useState([]);
  const history = useHistory();
  const {
    category_id,
    movie_id,
    content_lang_id,
    genre_id,
    isPrivate,
    reservation_id,
  } = useParams();

  const dispatch = useDispatch();

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const [GUEST_CHECKOUT, setGUEST_CHECKOUT] = useState(
    (globalOptions.find(g => g.go_key === `GUEST_CHECKOUT`)?.go_value || 'N') === 'Y'
  );

  useEffect(() => {
    setGUEST_CHECKOUT(
      (globalOptions.find(g => g.go_key === `GUEST_CHECKOUT`)?.go_value || 'N') === 'Y'
    )
    console.log({
      GUEST_CHECKOUT_FLAG: globalOptions.find(g => g.go_key === `GUEST_CHECKOUT`)?.go_value,
      GUEST_CHECKOUT
    })
  }, [globalOptions])

  const getStepWithOffset = (key, incrementBy1 = false) => {
    let k = parseInt(key);

    if (incrementBy1) k = k + 1
    if (typeof key === 'string') k = String(k)

    return k
  }

  const toggleHandle = (key) => {
    key = parseInt(key);

    if (GUEST_CHECKOUT) key = key + 1;

    setDefaultTab(`${key ? key : 0}`);
    setOpen((oldState) => {
      let openCopy = Array(8).fill(0);
      openCopy[key] = !oldState[key];
      return openCopy;
    });
  };
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);

  const loginDetails = useSelector((state) => state.auth);
  const {
    loggedInUser,
    userHistory,
    registerError,
    loginError,
    guestUser,
  } = loginDetails;
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const [registerLoading, setRegisterLoading] = useState(false);
  const [type, setType] = useState(null);
  const [
    registerConditionsCheckError,
    setRegisterConditionsCheckError,
  ] = useState(false);
  const handleChangeOtp = (value) => {
    setOtp(value);
  };
  useEffect(() => {
    category_id == 1
      ? dispatch(fetchReservationDetails({ reservation_id, category_id }))
      : dispatch(fetchReservationDetailsBrij({ reservation_id, category_id }));
    window.scrollTo(0, 0);
    dispatch(fetchStripePublisherKey());
    dispatch(fetchActivePaymentModes(2));
    dispatch(setReservationID(reservation_id));
  }, []);

  const booking_type = useSelector((state) => state.movies.booking_type);
  const reservationDetails = useSelector(
    (state) => state.movies.reservationDetails
  );
  const minutes = useSelector((state) => state.movies.timer.minutes);
  const seconds = useSelector((state) => state.movies.timer.seconds);
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const seatTypes = useSelector((state) => seatTypesSelector(state));

  useEffect(() => {
    console.log("seatTypes in payment page: ===========>>", seatTypes);
  }, [scheduleDetails]);

  useEffect(() => {
    if (timerCompleted === true) {
      // toast.dismiss();
      // toast.error("Your session has expired");
      // history.replace(
      //   `/create-show-details/${scheduleDetails?.movie_id}/${scheduleDetails?.content_lang_id}`
      // );
      dispatch({
        type: `SET_SHOW`,
        payload: {
          movie_id: scheduleDetails?.movie_id,
          content_lang_id: scheduleDetails?.content_lang_id,
          category_id,
        },
      });
      dispatch({
        type: `SET_TIMEOUT_ERROR`,
        payload: { message: `Your session has expired`, show: true },
      });
    }
  }, [timerCompleted]);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const [confirmDetails, setConfirmDetails] = useState(false);
  const [countryMobCodeGuest, setCountryMobCodeGuest] = useState(null);

  const onConfirmPayment = () => {
    console.log("category_id :>> ", category_id);
    // category_id == 1
    //   ? dispatch(
    //       cinemaBookSeats({
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser?.cust_id,
    //         email,
    //       })
    //     )
    //   : dispatch(
    //       bookSeats({bookSeats
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser?.cust_id,
    //         email,
    //       })
    //     );
    dispatch(
      cinemaBookSeats({
        reservation_id,
        cinema_id: scheduleDetails.cinema_id,
        customer_id: loggedInUser?.cust_id,
        email,
      })
    );

    if (isMobile) {
      toggleHandle(3);
    } else {
      toggleHandle(2);
    }
  };
  const onClickLoginConfirmDetails = (e, guestUser) => {
    if (e) {
      e.preventDefault();
    }

    // if (!loggedInConditionsCheck) {
    //   setLoginConditionsCheckError(true);
    //   return;
    // }
    setLoginSubmitted(true);
    if (!guestUser && (mobile === "" || email === "")) {
      return;
    } else if (guestUser && email === "") {
      return;
    }
    console.log("in confirm condition");
    setIsConfirmed(true);

    if (guestUser) {
      console.log({
        guest_country_code_og: guestUser.guest_country_code,
        guest_country_code: countryMobCodeGuest
      })
      let payload = {
        guest_email: guestUser.guest_email,
        guest_first_name: !!guestFirstName
          ? guestFirstName
          : guestUser.guest_first_name,
        guest_last_name: !!guestLastName
          ? guestLastName
          : guestUser.guest_last_name,
        guest_mobile: !!mobile ? mobile : guestUser.guest_mobile,
        guest_country_code: !!countryMobCodeGuest
          ? countryMobCodeGuest
          : guestUser.guest_country_code,
        callback: () => { },
      };
      dispatch(guestRegister(payload)).catch(console.error);
    }

    let openNew = open;
    openNew[0] = false;
    setOpen(openNew);
    setTimeout(() => {
      setConfirmDetails(true);
      isMobile ? toggleHandle(2) : toggleHandle(1);
    }, 100);
  };
  const onClickRegisterConfirmDetails = (e) => {
    e.preventDefault();
    setRegisterConditionsCheckError(false);
    if (!registerConditionsCheck) {
      setSubmitted(true);
      setRegisterConditionsCheckError(true);
      return;
    }

    console.log(memberBool === "guestCheckout", {
      memberBool,
    });

    if (memberBool === "guestCheckout") {
      setSubmitted(true);
      if (emailNotMatched) return;
      if (!registerEmail) return;
      if (!lastName) return;
      if (!firstName) return;

      setRegisterLoading(true);
      let payload = {
        guest_email: registerEmail,
        guest_first_name: firstName,
        guest_last_name: lastName,
        guest_mobile: registerMobile,
        guest_country_code: countryMobCode,
        callback: setRegisterLoading,
      };

      dispatch(guestRegister(payload)).then(r => {
        setTimeout(() => {
          toggleHandle(isMobile ? 2 : 1)
        }, 10);
      });
      return;
    }

    if (
      registerEmail === "" ||
      registerMobile === "" ||
      firstName === "" ||
      lastName === "" ||
      password === "" ||
      !registerConditionsCheck
      //  || !selectedDate
    ) {
      return;
    }
    setRegisterLoading(true);
    let payload = {
      email: registerEmail,
      first_name: firstName,
      last_name: lastName,
      password,
      phone_no: registerMobile,
      country_id: countryId,
      callback: setRegisterLoading,
    };
    if (selectedDate) payload.dob = moment(selectedDate).format("YYYY-MM-DD");

    dispatch(register(payload));
  };

  const onErrorHandling = () => {
    if (loginError === "Password is incorrect") {
      onClickForgotPassword();
    } else {
      setMemberBool("register");
      setLoginSubmitted(false);
    }
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setType("email");
    setLoginSubmitted(true);
    let count = 0;

    if (
      (!isNumber && loginField === "") ||
      (isNumber && (loginField === "" || !countryId))
    ) {
      count++;
    }

    if (loginPassword === "") {
      count++;
    }

    if (count > 0) {
      setLoading(false);
      return;
    }

    dispatch(
      loginAction({
        loginField,
        password: loginPassword,
        callback: setLoading,
        countryId,
      })
    );
  };

  const show = useSelector((state) => state.movies.show);
  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);

  const bookingSuccessful = useSelector(
    (state) => state.movies.bookingSuccessful
  );
  const [email, setEmail] = useState(``);
  const [mobile, setMobile] = useState(``);
  const [loggedInConditionsCheck, setLoggedInConditionsCheck] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const emailInput = React.useRef(null);
  const mobileInput = React.useRef(null);

  const [guestFirstName, setGuestFirstName] = useState(``);
  const [guestLastName, setGuestLastName] = useState(``);
  const guestFirstNameInput = React.useRef(null);
  const guestLastNameInput = React.useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      emailInput.current.focus();
    }
  }, [email]);
  useEffect(() => {
    if (loggedInUser) {
      mobileInput.current.focus();
    }
  }, [mobile]);
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const [loading, setLoading] = useState(false);
  const selected_seats = useSelector(
    (state) => state.seatLayout.seats_selected
  );
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const isUnpaidBooking = useSelector((state) => state.movies.isUnpaidBooking);
  const netAmount = useSelector((state) => state.movies.netAmount);
  const [registerEmail, setRegisterEmail] = useState(``);
  const [confirmregisterEmail, setconfirmRegisterEmail] = useState(``);
  const [emailNotMatched, setEmailNotMatched] = useState(false);
  const [registerMobile, setRegisterMobile] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [defaultTab, setDefaultTab] = useState("0");
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [registerConditionsCheck, setRegisterConditionsCheck] = useState(false);
  const [loginConditionsCheckError, setLoginConditionsCheckError] = useState(
    false
  );
  const [submitted, setSubmitted] = useState(false);
  const [loginSubmitted, setLoginSubmitted] = useState(false);
  const [loginSubmittedViaOTP, setLoginSubmittedViaOTP] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [allowResendLogin, setAllowResendLogin] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [allowForgotPasswordResend, setAllowForgotPasswordResend] = useState(
    false
  );
  const [fpField, setFpField] = useState("");

  const [loginField, setLoginField] = useState("");
  const [isNumber, setisNumber] = useState(true);

  useEffect(() => {
    let ifNumber = numberRegex(loginField);
    if (!ifNumber) setCountryId(null);

    setisNumber(ifNumber);
  }, [loginField]);

  const [dobError, setDobError] = useState(false);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [ogDate, setOgDate] = useState(null);

  const state = useSelector((state) => state);
  const {
    auth: { loginOtpError },
  } = state;

  const isLoginViaOTPClicked = useRef(false);

  const onClickLoginViaOTP = () => {
    setType("otp");
    if (isLoginViaOTPClicked.current === true) {
      return;
    }

    setLoginSubmittedViaOTP(true);
    let count = 0;
    if (loginField === ``) {
      count++;
    }

    if (count > 0) {
      return;
    }
    setLoadingOtp(true);
    // setSubmittedviaOtp(false);
    isLoginViaOTPClicked.current = true;

    setTimeout(() => {
      isLoginViaOTPClicked.current = false;
    }, 5 * 1000);

    dispatch(
      loginViaOtp({
        loginField,
        callback: setLoadingOtp,
        successCallback: () => {
          setMemberBool("otp-login");
          dispatch(startTimer());
          setAllowResendLogin(false);
          setTimeout(() => setAllowResendLogin(true), 30000);
        },
      })
    );
  };

  const onClickForgotPassword = () => {
    setOtpPopup(true);
    setMemberBool("forgot-password");
  };

  const onClickResendLogin = () => {
    if (allowResendLogin) {
      setAllowResendLogin(false);
      dispatch(
        loginViaOtp({
          loginField,
          callback: () => { },
          successCallback: () => {
            dispatch(resetTimer());
            dispatch(startTimer());
            setAllowResendLogin(false);
            setInterval(() => setAllowResendLogin(true), 30000);
          },
        })
      );
    }
  };

  const onClickResend = () => {
    if (allowForgotPasswordResend) {
      setAllowForgotPasswordResend(false);
      dispatch(
        forgotPassword({
          field: fpField,
          callback: () => { },
          successCallback: () => {
            setAllowForgotPasswordResend(false);
            dispatch(resetTimer());
            dispatch(startTimer());
            setInterval(() => setAllowForgotPasswordResend(true), 30000);
          },
        })
      );
    }
  };

  const emailRegisterInput = React.useRef(null);
  const confirmemailRegisterInput = React.useRef(null);
  const mobileRegisterInput = React.useRef(null);
  const fNameRegisterInput = React.useRef(null);
  const lnameRegisterInput = React.useRef(null);
  const passwordRef = React.useRef(null);

  useEffect(() => {
    const existingGuestUserId = localStorage.getItem(`guest_user_id`);
    if (!existingGuestUserId && !loggedInUser) {
      const guest_user_id = Math.floor(100000000 + Math.random() * 900000000);
      localStorage.setItem(`guest_user_id`, guest_user_id);
    }
  }, []);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      emailRegisterInput.current.focus();
    }
  }, [registerEmail]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      confirmemailRegisterInput.current.focus();
    }
  }, [confirmregisterEmail]);

  useEffect(() => {
    if (!loggedInUser && !guestUser && registerEmail !== confirmregisterEmail) {
      setEmailNotMatched(true);
    } else {
      setEmailNotMatched(false);
    }
  }, [registerEmail, confirmregisterEmail]);

  useEffect(() => {
    console.log({ guestUser });
  }, [guestUser]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      mobileRegisterInput.current.focus();
    }
  }, [registerMobile]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      fNameRegisterInput.current.focus();
    }
  }, [firstName]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      lnameRegisterInput.current.focus();
    }
  }, [lastName]);
  useEffect(() => {
    if (loggedInUser) {
      setEmail(loggedInUser?.email || loggedInUser?.cust_email);
      setMobile(loggedInUser?.phone_no || loggedInUser?.cust_mobile);
    } else if (guestUser) {
      setEmail(guestUser.guest_email);
      if (!!guestUser.guest_mobile) setMobile(guestUser.guest_mobile);
      if (!!guestUser.guest_first_name)
        setGuestFirstName(guestUser.guest_first_name);
      if (!!guestUser.guest_last_name)
        setGuestLastName(guestUser.guest_last_name);
    }
  }, [loggedInUser, guestUser]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      passwordRef.current.focus();
    }
  }, [password]);
  useEffect(() => {
    if (reservation_id === null) {
      history.replace(`/movie-details/${movie_id}/${content_lang_id}`);
    }
  }, [reservation_id, history]);
  //Start User Register Form
  const UserRegisterForm = () => {
    return <p></p>;
  };
  //End User Register Form

  //Start User Contact Information
  const UserContactInfo = () => {
    return <p></p>;
  };

  //Start OTP Screen
  const PaymentOtp = () => {
    return (
      <>
        <div className="col-md-10 col-lg-9 col-xl-7 px-xl-0 mx-auto otp-form">
          <p className="error">
            Almost there! <br />
            Please enter the 4 digit verification code you might have just
            received via email/ SMS.
          </p>
          <div className="otp-input">
            <OtpInput
              value={otp}
              onChange={handleChangeOtp}
              numInputs={4}
              separator={<span></span>}
            />
          </div>
          <div>
            <button className="payment-submit-btn btn-main">
              Verify Your Account
            </button>
          </div>
          {/* <div className="next-step">
            <p>Next Step</p>
            <div>
              <img
                src={greyArrowDown}
                alt="Arrow Down"
                className="grey-arrow-down"
              />
            </div>
          </div> */}
        </div>
      </>
    );
  };
  //End OTP Screen

  //Start Card Details
  const PaymentCardDetails = () => {
    return (
      <>
        <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success payment-card">
          <div>
            <h4 className="font-weight-bold">Pay $150</h4>
            <p className="grey-font">Transaction #1234567889</p>
          </div>
          <div className="custom-form">
            <div>
              <label>Card Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="1234 5678 1234 5678"
              />
            </div>
            <div>
              <label>Name on card</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name Surname"
              />
            </div>
            <div className="grid-col">
              <div>
                <label>Expiry Date</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="11/24"
                />
              </div>
              <div>
                <label>CVV2</label>
                <input type="text" className="form-control" placeholder="123" />
              </div>
            </div>
          </div>
          <div className="mb-0 mt-n3">
            <button className="payment-submit-btn">Pay Now</button>
          </div>
        </div>
      </>
    );
  };
  //End Card Details

  //End User Contact Information

  //Start Payment Confirm Message
  const PaymentConfirmMessage = ({ t }) => {
    return (
      <>
        <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
          {/* <div> */}
          {/* <h4 className="font-weight-bold">Pay ₹{totalAmount}</h4> */}
          {/* <p className="grey-font">Transaction #1234567889</p> */}
          {/* </div> */}
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={paymentSuccessIcon}
              alt="Payment Success"
              className=""
            />
          </div>
          <div className="pt-2">
            <h2 className="font-weight-bold">
               {t("Enjoy your movie!")}
            </h2>
            <p className="px-lg-5 pt-2">
               {t("Your transaction was successfully processed, check your email for your tickets")}
            </p>
          </div>
          <div className="mb-0">
            <button
              className="payment-submit-btn"
              onClick={() => {
                console.log({ isUnpaidBooking });
                if (isUnpaidBooking)
                  history.push(
                    `/unpaid-ticket/${reservation_id}/${category_id}`
                  );
                else
                  history.replace(
                    `/final-ticket/${showBookingID}/${category_id}`
                  );
              }}
            >
               {t("Close and collect ticket")}
            </button>
          </div>
        </div>
      </>
    );
  };
  //End Confirm Message
  const onClickPay = () => {
    alert(1);
    dispatch(
      cinemaBookSeats({ reservation_id, cinema_id: scheduleDetails.cinema_id })
    );
  };
  const [memberBool, setMemberBool] = useState("login");
  const [switchTo, setSwitchTo] = useState("guestCheckout");
  const appliedVoucher = useSelector(
    (state) => state.promotions.applied_voucher
  );
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;

  const [back, setBack] = useState(false);

  let private_rate_card_detail =
    reservationDetails?.private_rate_card_detail ?? [];

  const booking_type_id = reservationDetails?.booking_type_id ?? null;

  useEffect(() => {
    if (GUEST_CHECKOUT && (loggedInUser || guestUser)) {
      if (!isMobile) {
        setDefaultTab("1");
        setOpen([0, true, 0, 0, 0, 0, 0, 0]);
      }
      else {
        setDefaultTab("2");
        setOpen([0, 0, true, 0, 0, 0, 0, 0]);
      }
    }
  }, [loggedInUser, GUEST_CHECKOUT, isMobile]);

  const reservationDetailsLoading = useSelector((state) => state.movies.reservationDetailsLoading);
  const timeoutError = useSelector((state) => state.movies.timeoutError);
  return (
    <>
      <LoginPopup show={login} closeFunc={setLogin} />

      <section className="payment-page">
        <div className="container-fluid px-3">
          {/* OLD BACK BUTTON */}
          {false && <div className="back-header">
            {!isMobile &&
              <button
              className="btn-main mt-2 ml-3"
              style={{ minWidth: "unset" }}
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
              onClick={() => history.go(-1)}
            >
              {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              /> */}
              {back ? <ArrowBackActive /> : <ArrowBack />}
            </button>}
            <div className="ml-auto mr-auto mr-md-0 ml-md-auto fs-14 px-3 px-md-0">
              {!timeoutError?.show
                ? (<p>
                  {t("Time Remaining")}: {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </p>)
                : (<p>
                  {t("Time Remaining")}: {"00"}:{"00"}
                </p>)
              }
            </div>
          </div>}
          
          {/* <button onClick={onClickPay}>Pay Now</button> */}
          <div className="row page-content">
            {!isMobile && (
              <div className="col-md-4 col-xl-3 payment-booking-details pl-md-0">
                {
                  !!reservationDetailsLoading ? (
                      <div className="row">
                        <div className="col-12 text-center">
                          <Spinner/>
                        </div>
                      </div>
                    )
                    :
                    (!timeoutError || !timeoutError?.show)
                      ? (<PaymentBookingDetails category_id={category_id} t={t}/>)
                      : <></>
                }
              </div>
            )}

            <div className="col-md-8 col-xl-9 userDetailsAccordian pr-0 pl-0 pl-md-3">
              {/* NEW BACK BUTTON */}
                {!isMobile ?
                <>
          <div className="inner_pages_header">
                
                  <GoBack />
                    <div className="custom_breadcrumbs" style={{ borderBottom: 'none' }}>
                {/*<span onClick={()=>history.push("now-showing")}>
                  Now Showing</span> <span  onClick={()=>history.push("now-showing-booking")}>
                  Now Showing Booking</span> 
                  <span>
                  Checkout</span> */}
                </div>
                   <div className="fs-14  pl-3 pl-md-0 pr-3" style={{whiteSpace:"nowrap"}}>
              {!timeoutError?.show
                ? (<p>
                  {t("Time Remaining")}: {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </p>)
                : (<p>
                  {t("Time Remaining")}: {"00"}:{"00"}
                </p>)
              }
          </div>
            </div>
                  
                  </> :
             <div className="fs-14  pl-3 pl-md-0 pr-3 mb-3">
              {!timeoutError?.show
                ? (<p>
                  {t("Time Remaining")}: {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </p>)
                : (<p>
                  {t("Time Remaining")}: {"00"}:{"00"}
                </p>)
              }
                  
          </div>
                }
                
              <Accordion activeKey={open[parseInt(defaultTab)] && defaultTab}
              className={`booking_accordion`}>
                {isMobile && (
                  <Card className="">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      onClick={() => {
                        toggleHandle(GUEST_CHECKOUT ? -1 : 0)
                      }}
                      className={open[0] ? "active" : ""}
                    >
                      {/* <div className="number-circle">1</div> */}
                      {/* <span>{t("Please enter contact information")}</span> */}
                      <article>
                        <h2>
                        <span>1.&nbsp;</span>
                          {open[0] ? t("Verify movie details") : t("Movie details")}
                        </h2>
                      </article>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="collapse-padding" eventKey="0">
                      <article className="w-100 payment-booking-details booking_accordion_step">
                        {/* OLD DESIGN */}
                        {false &&
                          <>
                        <div className="header">
                          <div className="left">
                            <img
                              src={
                                getLangSpecificAttribute(
                                  scheduleDetails?.languageSpecificImages,
                                  lang_id,
                                  "artwork"
                                ) ||
                                scheduleDetails?.md_thumbnail_url ||
                                dummyPoster
                              }
                              alt="Movie Img"
                              className="movie-img"
                            />
                          </div>
                          <div className="right">
                            <div>
                              <h6 className="title mb-1">
                                {getLangSpecificAttribute(
                                  scheduleDetails?.altMovieContent,
                                  lang_id,
                                  "mc_title"
                                ) || scheduleDetails?.movie_title}
                              </h6>
                              <div className="features">
                                <p className="mb-1">
                                  {scheduleDetails?.is_version_apply
                                    ? scheduleDetails?.version_name ??
                                    scheduleDetails?.lang_name
                                    : scheduleDetails?.lang_translation_obj?.[
                                    iso_2?.toLowerCase()
                                    ] ?? scheduleDetails?.lang_name}{" "}
                                  {scheduleDetails?.mf_name}
                                </p>
                                <p className="mb-1">
                                  {scheduleDetails?.mrrdr_runtime}{" "}
                                  {t("common.mins")}{" "}
                                </p>
                                <p>
                                  {moment(
                                    scheduleDetails?.mrrdr_release_date
                                  ).format("YYYY")}{" "}
                                </p>
                              </div>
                              <div className="features">
                                {moment(
                                  scheduleDetails?.ss_actual_start_date_time
                                ).format("ll")}{" "}
                                |{" "}
                                {moment(
                                  scheduleDetails?.ss_start_show_time,
                                  "HH:mm:ss"
                                ).format("HH:mm")}
                              </div>
                            </div>
                            {/* <div className="movie-imbd">
            <p className="fs-13">{t("IMDB")}</p>
            <p className="value">7.5 / 10</p>
          </div> */}
                            <div className="movie-rating">
                              <p className="fs-13">
                                {t("Rating")}&nbsp;:&nbsp;
                                <span className="value small-font">
                                  {scheduleDetails?.rating}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* End Header */}

                        <div className="middle">
                          {false && (
                            <>
                              <div className="item">
                                <div className="heading">
                                  {t("Cinema")}:{" "}
                                  {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
                                </div>
                                <div className="content">
                                  {scheduleDetails?.cine_name},{" "}
                                  {scheduleDetails?.cine_address}
                                  {/* {scheduleDetails?.cine_location} */}
                                </div>
                              </div>
                              <div className="item">
                                <div className="heading">
                                  {t("Date")} & {t("Time")}:{" "}
                                  {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
                                </div>
                                <div className="content">
                                  {moment(
                                    scheduleDetails?.ss_actual_start_date_time
                                  ).format("ll")}{" "}
                                  |{" "}
                                  {moment(
                                    scheduleDetails?.ss_start_show_time,
                                    "HH:mm:ss"
                                  ).format("HH:mm")}
                                </div>
                              </div>
                              {category_id != 2 && (
                                <div>
                                  <p>{t("Screening Type")}:</p>
                                  <p>
                                    {/* {booking_type == 1
                ? t("Confirmed Screening")
                : booking_type == 2
                ? t("Crowdsourced Screening")
                : booking_type == 3
                ? t("Private Screening")
                : null} */}
                                    {scheduleDetails?.booking_type_name}
                                  </p>
                                </div>
                              )}
                              <div className="item">
                                <div className="heading">
                                  {t("Selected Seats")} (
                                  {scheduleDetails?.ticket_count}):
                                </div>
                                <div className="content">
                                  {/* {booking_type !== 3 && (
              // selected_seats?.map((seat, index) => (
              //   <>
              //     {seat.sl_seat_name}
              //     {selected_seats.length - 1 !== index && ","}
              //   </>
              // ))
              
            )}
            {booking_type === 3 && <p>{t("common.All")}</p>} */}
                                  <p>{scheduleDetails?.seat_name}</p>
                                </div>
                              </div>

                              <div className="item">
                                <div className="heading">
                                  {t("Ticket Type(s)")}:
                                </div>
                                <div className="content">
                                  <div>Premium 2 Adult, 2 Child</div>
                                  <div>Regular 2 Adult, 2 Child</div>
                                </div>
                              </div>

                              <div className="item">
                                <div className="heading">
                                  {t("Concessions")}:
                                </div>
                                <div className="content concession">
                                  <div className="d-flex justify-content-between align-items-center pb-1">
                                    <div className="fs-16">
                                      Popcorn <span class="text-light">x2</span>
                                    </div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center pb-1">
                                    <div className="fs-16">
                                      Burger <span class="text-light">x2</span>
                                    </div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center pb-1">
                                    <div className="fs-16">
                                      Pizza <span class="text-light">x2</span>
                                    </div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center border-top pt-2 mt-2">
                                    <div className="fs-16">Total</div>
                                    <div className="fs-16">CHF 99.99</div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
                                <div className="fs-16">
                                  {t("final_ticket_page.Tickets")} (
                                  {booking_type === 3
                                    ? "All"
                                    : scheduleDetails?.ticket_count}
                                  ):{" "}
                                </div>
                                <div className="fs-22 font-weight-bold">
                                  {curr_code}{" "}
                                  {currencyFormatter(reservationDetails?.amount)}
                                </div>
                              </div>
                              {discount && (
                                <div className="d-flex justify-content-between align-items-center pb-2">
                                  <div className="fs-16">
                                    {t("Discount Code")}:
                                    <br />({appliedVoucher.voucher_title})
                                  </div>
                                  <div className="fs-22 green-text font-weight-bold">
                                    - {curr_code} {currencyFormatter(discount)}
                                  </div>
                                </div>
                              )}

                              <div className="d-flex justify-content-between align-items-center pb-3">
                                <div className="fs-16">
                                  {t("VAT(Incl. 2.5%)")}
                                </div>
                                <div className="fs-22 font-weight-bold">
                                  CHF 20.00
                                </div>
                              </div>
                            </>
                          )}

                          {/* new layout */}
                          <article className="grid-50">
                            <div>
                              <p className="grey-title">{t("Cinema")}: </p>
                              <p>
                                {" "}
                                {scheduleDetails?.cine_name},{" "}
                                {scheduleDetails?.cine_address}
                                {/* {scheduleDetails?.cine_location} */}
                              </p>
                            </div>

                            {scheduleDetails?.booking_type_name && (
                              <div className="">
                                <p className="grey-title">
                                  {t("Screening Type")}:
                                </p>
                                <p className="">
                                  {scheduleDetails?.booking_type_name}
                                </p>
                              </div>
                            )}
                          </article>

                          {scheduleDetails?.seat_name && (
                            <>
                              <article className="grid-50">
                                <p className="grey-title">
                                  {t("Selected Seats")} (
                                  {scheduleDetails?.ticket_count}):
                                </p>
                                <p className="">{scheduleDetails?.seat_name}</p>
                              </article>
                            </>
                          )}

                          {seatTypes?.length > 0 && (
                            <article className="grid-25 primary-title bt-1 pt-2">
                              <div className="">{t("Ticket Type")}</div>
                              <div className="">{t("Qty.")}</div>
                              <div className="">{t("Price/ticket")}</div>
                              <div className="">{t("Total Price")}</div>
                            </article>
                          )}

                          {seatTypes?.length > 0 &&
                            seatTypes.map((seatType, seatTypeIndex) => (
                              <>
                                <article
                                  className="grey-title-lg"
                                  key={seatTypeIndex}
                                >
                                  {seatType.sst_seat_type}
                                </article>

                                {seatType?.seats?.length > 0 &&
                                  seatType.seats.map((rcg_child, index2) => (
                                    <article className="grid-25">
                                      <div className="">
                                        {rcg_child?.ticket_type_translations?.[
                                          iso_2?.toLowerCase()
                                        ] || rcg_child.tt_name}
                                      </div>
                                      <div className="">
                                        x{rcg_child.no_of_seats}
                                      </div>
                                      <div className="">
                                        {curr_code}{" "}
                                        {currencyFormatter(
                                          rcg_child.single_seat_price
                                        )}
                                      </div>
                                      <div className="">
                                        {curr_code}{" "}
                                        {currencyFormatter(
                                          rcg_child.total_price
                                        )}
                                      </div>
                                    </article>
                                  ))}
                              </>
                            ))}

                          {selectedFnbItems && selectedFnbItems.length > 0 && (
                            <article className="grey-title-lg">
                              {t("Concessions")}
                            </article>
                          )}

                          {selectedFnbItems &&
                            selectedFnbItems.length > 0 &&
                            selectedFnbItems.map((item) => (
                              <article className="grid-25">
                                <div>
                                  <p className="">
                                    {getLangSpecificAttribute(
                                      item?.FnbContent,
                                      lang_id,
                                      "item_lang_name"
                                    ) || item.item_name}
                                  </p>
                                </div>
                                <div>
                                  <p className="">x{item.Quantity}</p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(item.fp_total_amount)}
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(item.total_price)}
                                  </p>
                                </div>
                              </article>
                            ))}

                          {discount && (
                            <article className="grid-50">
                              <div>
                                <p className="grey-title-lg discount">
                                  {t("Discount")}:{" "}
                                </p>
                              </div>
                              <div>
                                <p className="discount">
                                  {" "}
                                  - {curr_code} {currencyFormatter(discount)}
                                </p>
                              </div>
                            </article>
                          )}

                          <article className="grid-50 grand-total">
                            <p className="grey-title-lg d-flex flex-column">
                              {t("Grand Total")}
                              <span>
                                {t("(Incl. of 2.5% VAT - ")} {curr_code}{" "}
                                {currencyFormatter(
                                  _.round(
                                    totalAmount -
                                    netAmount +
                                    fnbPrice.total_tax,
                                    4
                                  )
                                )}{" "}
                                {")"}
                              </span>
                            </p>
                            <p className="d-flex align-items-center justify-content-end">
                              {curr_code}{" "}
                              {currencyFormatter(
                                reservationDetails?.amount +
                                fnbPrice.total_price -
                                (discount ? discount : 0)
                              )}
                            </p>
                          </article>
                        </div>
                        {/* End Middle */}
                        {/* 
                        <div className="d-flex justify-content-between footer">
                          <div>
                            <p className="fs-16">
                              {t("Total Amount Payable")}:{" "}
                            </p>
                            <p className="fs-10 text-grey font-italic">
                              ({t("Amount inclusive of all taxes")})
                            </p>
                          </div>
                          <p className="fs-22 font-weight-bold">
                            {curr_code}{" "}
                            {currencyFormatter(
                              reservationDetails?.amount -
                                (discount ? discount : 0)
                            )}
                          </p>
                        </div> */}

                        {/* End Footer */}
                        </>}
                        {/* NEW DESIGN */}
                        {
                          !!reservationDetailsLoading ? (
                              <div className="row">
                                <div className="col-12 text-center">
                                  <Spinner/>
                                </div>
                              </div>
                            )
                            :
                            (!timeoutError || !timeoutError?.show)
                              ? (<PaymentBookingDetails category_id={category_id} t={t}/>)
                              : <></>
                        }
                        {!GUEST_CHECKOUT && (<button
                          className="btn-main payment-submit-btn mt-3"
                          onClick={() => toggleHandle(1)}
                        >
                          {t("Confirm")}
                        </button>)}
                      </article>
                    </Accordion.Collapse>
                  </Card>
                )}

                {GUEST_CHECKOUT && (
                  <Card className={``}>
                    <Accordion.Toggle
                      as={Card.Header}
                      
                      eventKey={getStepWithOffset('-1', GUEST_CHECKOUT)}
                      onClick={(e) => {
                        if (loggedInUser) return;
                        if (confirmDetails) return;
                        if (bookingSuccessful === true) {
                          e.preventDefault();
                          e.stopPropagation();
                          //return false;
                        }
                        loggedInUser && isMobile
                          ? toggleHandle(0)
                          : toggleHandle(-1);
                      }}
                      className={
                        (isMobile
                          ? open[getStepWithOffset(0, GUEST_CHECKOUT)]
                          : open[getStepWithOffset(-1, GUEST_CHECKOUT)]
                        )
                          ? "active"
                          : ""
                      }
                    >
                      {/* <div className="number-circle">{
                        getStepWithOffset(isMobile ? "1" : "0", GUEST_CHECKOUT)
                      }</div> */}
                      <article>
                       
                        <h2>
                           <span>{
                        getStepWithOffset(isMobile ? "1" : "0", GUEST_CHECKOUT)
                      }.&nbsp;</span>{
                          !isMobile
                           ? t("Sign in/Sign up to become a member")
                           : (loggedInUser || guestUser)
                            ? t("Signed In")
                            : t("Sign in/Sign up to become a member")
                        }</h2>
                      </article>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={getStepWithOffset('-1', GUEST_CHECKOUT)}
                      id="collapse-padding"
                    >
                      <article className="booking_accordion_step">
                        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto payment_auth_options my-0">
                          <>
                            <button
                              className={`btn-main mx-auto guest_btn`}
                              onClick={(e) => {
                                if (bookingSuccessful === true) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  //return false;
                                }
                                setMemberBool("guestCheckout");
                                setSwitchTo("guestCheckout");
                                if (isMobile) {
                                  toggleHandle(1);
                                } else {
                                  toggleHandle(0);
                                }
                              }}
                            >
                              {t("Guest Checkout")}
                            </button>
                            <button
                              className={`btn-main mx-auto`}
                              onClick={(e) => {
                                if (bookingSuccessful === true) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  //return false;
                                }
                                setMemberBool("login");
                                setSwitchTo("login");
                                if (isMobile) {
                                  toggleHandle(1);
                                } else {
                                  toggleHandle(0);
                                }
                              }}
                            >
                              {t("Sign In")}
                            </button>
                          </>
                        </div>
                        <div className="mt-3">
                          <p
                            className="text-center not_a_member"
                            onClick={(e) => {
                              if (bookingSuccessful === true) {
                                e.preventDefault();
                                e.stopPropagation();
                                //return false;
                              }
                              setMemberBool("register");
                              setSwitchTo("register");
                              if (isMobile) {
                                toggleHandle(1);
                              } else {
                                toggleHandle(0);
                              }
                            }}
                          >
                            {t("Not a member yet")}?{" "}
                            <a href="#">{t(`header-footer-menu.signup`)}</a>
                          </p>
                        </div>
                      </article>
                    </Accordion.Collapse>
                    {/* ) : null} */}
                  </Card>
                )}

                <Card
                  className={``}
                >
                  <Accordion.Toggle
                    as={Card.Header}
                    
                    eventKey={getStepWithOffset(isMobile ? "1" : "0", GUEST_CHECKOUT)}
                    onClick={(e) => {
                      if (bookingSuccessful === true) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }
                      if (isMobile) {
                        toggleHandle(1);
                      } else {
                        toggleHandle(0);
                      }
                    }}
                    className={`
                    ${
                      (isMobile
                        ? open[getStepWithOffset(1, GUEST_CHECKOUT)]
                        : open[getStepWithOffset(0, GUEST_CHECKOUT)]
                      )
                        ? "active"
                        : ""
                    } 
                    ${(isMobile
                    ? open[getStepWithOffset(1, GUEST_CHECKOUT)]
                    : open[getStepWithOffset(0, GUEST_CHECKOUT)]
                  ) && "inactive"
                    }`}

                  >
                    {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "2" : "1", GUEST_CHECKOUT)
                    }</div> */}
                    {/* <span>{t("Please enter contact information")}</span> */}
                    <article className="current-user-details-card-header">
                      <h2>
                        <span className={`${ isMobile && open[getStepWithOffset(1, GUEST_CHECKOUT)] }`}>{
                      getStepWithOffset(isMobile ? "2" : "1", GUEST_CHECKOUT)
                    }.&nbsp;</span>
                      {
                        isMobile && open[getStepWithOffset(1, GUEST_CHECKOUT)] !== true && (loggedInUser || guestUser)
                        ? t('Verified')
                        :
                        switchTo !== "guestCheckout" || !guestUser
                          ? t(
                            "Verify your details" // "Sign in/Sign up to become a member"
                          )
                          : t("Verify your details as guest")
                      }
                      </h2>
                      {!isMobile && ((open[0] != 1) || (open[0] != true)) && <>
                      <p className="text-uppercase text-primary">
                        {memberBool !== "guestCheckout"
                          ? !!guestUser && switchTo == "guestCheckout"
                            ? t("Guest Checkout")
                            : <>{
                              ''
                                // t(`header-footer-menu.Sign in`) } / { t(`header-footer-menu.signup`)
                            }</>
                          : t("Guest Checkout")
                      }
                        </p>
                       {switchTo === "guestCheckout" && email && <p className="current-user-details-card-header-email">{email}</p>}
                      </>}
                    </article>
                  </Accordion.Toggle>
                  {/* {defaultTab == "0" ? ( */}
                  <Accordion.Collapse
                    eventKey={getStepWithOffset(isMobile ? "1" : "0", GUEST_CHECKOUT)}
                    id="collapse-padding"
                  >
                    {/* <> */}
                    {guestUser && switchTo === "guestCheckout" ? (
                      <>
                        <form
                          onSubmit={(e) =>
                            onClickLoginConfirmDetails(e, guestUser)
                          }
                          className="booking_accordion_step"
                        >
                          <div className="custom-form col-md-10 col-lg-9 col-xl-7 px-0 mx-auto">
                            <div>
                              <label>
                                <span> {t("login_form.Email")}</span>
                              </label>
                              <input
                                disabled={guestUser ? true : undefined}
                                ref={emailInput}
                                type="email"
                                className="form-control"
                                placeholder={t(
                                  "placeholder.Enter your Email ID"
                                )}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />

                              <p className="error">
                                {loginSubmitted &&
                                  email === "" &&
                                  t(
                                    "login_form.error_messages.Email is required"
                                  )}
                              </p>
                            </div>
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-6 pr-lg-2">
                                <label><span>
                                  {t("login_form.First Name")}</span></label>
                                <input
                                  ref={guestFirstNameInput}
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your first name"
                                  )}
                                  value={guestFirstName}
                                  onChange={(e) =>
                                    setGuestFirstName(e.target.value)
                                  }
                                />
                                <p className="error">
                                  {loginSubmitted &&
                                    guestFirstNameInput === "" &&
                                    t("field_required")}
                                </p>
                              </div>
                              <div className="col-lg-6 pl-lg-2">
                                <label>
                                  <span>  {t("login_form.Last Name")}</span>
                                </label>
                                <input
                                  ref={guestLastNameInput}
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your last name"
                                  )}
                                  value={guestLastName}
                                  onChange={(e) =>
                                    setGuestLastName(e.target.value)
                                  }
                                />

                                <p className="error">
                                  {loginSubmitted &&
                                    guestLastNameInput === "" &&
                                    t("field_required")}
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <label className="w-100">
                                <span className="d-inline-block mb-2"> {t("login_form.Phone Number")}</span>
                                <div className="d-flex">


                                  <CountryCodeDropdown
                                    className="form-control mr-2"
                                    onSelectCountry={({
                                      country_id,
                                      country_mob_code,
                                    }) => {
                                      setCountryId(country_id);
                                      setCountryMobCode(country_mob_code);
                                      setCountryMobCodeGuest(country_mob_code);
                                    }}
                                  />

                                  <input
                                    ref={mobileInput}
                                    type="number"
                                    min="10"
                                    className="form-control mb-0"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your phone number"
                                    )}
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                  />


                                </div>
                              </label>
                            </div>
                            <p className="error">
                            </p>

                            <div className="pt-4 pb-3">
                              <button
                                className="payment-submit-btn btn-main"
                                type="submit"
                              >
                                {t("Confirm")}
                              </button>
                            </div>
                          </div>
                        </form>
                      </>
                    ) : loggedInUser ? (
                      // USER CONTACT INFO START
                      <>
                        <form
                          onSubmit={(e) => onClickLoginConfirmDetails(e)}
                          className="booking_accordion_step"
                        >
                          <div className="custom-form col-md-10 col-lg-9 col-xl-7 px-0 mx-auto">
                            <div>
                              <label>
                                <span> {t("login_form.Email")}</span>
                              </label>
                              <input
                                ref={emailInput}
                                type="email"
                                className="form-control"
                                placeholder={t(
                                  "placeholder.Enter your Email ID"
                                )}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />

                              {/* {loginSubmitted && email === "" && ( */}
                              <p className="error">
                                {loginSubmitted &&
                                  email === "" &&
                                  t(
                                    "login_form.error_messages.Email is required"
                                  )}
                              </p>
                              {/* )} */}
                              </div>
                              <label>
                                <span> {t("login_form.Phone Number")}</span>
                              </label>
                            <div class="d-flex">
                              <CountryCodeDropdown
                                className="form-control mr-2"
                                onSelectCountry={({
                                  country_id,
                                  country_mob_code,
                                }) => {
                                  setCountryId(country_id);
                                  setCountryMobCode(country_mob_code);
                                }}
                              />
                              
                              <input
                                ref={mobileInput}
                                type="number"
                                min="10"
                                className="form-control mb-0"
                                placeholder={t(
                                  "login_form.placeholders.Enter your phone number"
                                )}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                              />

                              {/* {loginSubmitted && mobile === "" && ( */}

                              {/* )} */}
                            </div>
                            <p className="error">
                              {loginSubmitted &&
                                mobile === "" &&
                                t(
                                  "login_form.error_messages.Phone number is required"
                                )}
                            </p>
                            {/* <div className="agree-check">
                             <input
                               className="form-check-input"
                               type="checkbox"
                               name="exampleRadios"
                               id="exampleRadios1"
                               checked={loggedInConditionsCheck}
                               onChange={(e) => setLoggedInConditionsCheck(e.target.checked)}
                             />
                             <label className="form-check-label pt-1 pl-1" for="exampleRadios1">
                               I agree to all Terms & Conditions
                             </label>
                             {loginConditionsCheckError && (
                               <p className="error">
                                 You must agree to all Terms & Conditions
                               </p>
                             )}
                           </div> */}

                            <div class="pt-4 pb-3">
                              <button
                                className="payment-submit-btn btn-main"
                                type="submit"
                              >
                                {t("Confirm")}
                              </button>
                            </div>
                          </div>
                        </form>
                        {/* <div className="next-step pb-3">
                          <p>{t("Next Step")}</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <>
                        <form
                          onSubmit={(e) => onClickRegisterConfirmDetails(e)}
                          className="booking_accordion_step"
                        >
                          <div className="custom-form col-lg-9 col-xl-7 px-0 mx-auto">
                            {/* <div
                              className="register-btn mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button
                                className="payment-submit-btn"
                                onClick={() => setMemberBool("login")}
                              >
                                 <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                /> 
                                {t("Login")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div>
                              <button
                                className={`mb-3 payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                                onClick={() => setMemberBool("register")}
                              >
                                {t("Register")}
                              </button>
                            </div> */}
                            {/* {memberBool ? ( */}
                             {memberBool !== "guestCheckout" && <div className="d-flex w-100 justify-content-around align-items-center mb-3 register_tabs">
                              {memberBool !== "guestCheckout" && (
                                <div
                                  className={`w-100 tabs py-2 ${memberBool === "login" && "active"
                                    }`}
                                  onClick={() => {
                                    setMemberBool("login");
                                  }}
                                >
                                  {t(`header-footer-menu.Sign in`)}
                                </div>
                              )}

                              {memberBool !== "guestCheckout" && (
                                <div
                                  className={`w-100 tabs py-2 ${memberBool === "register" && "active"
                                    }`}
                                  onClick={() => {
                                    setMemberBool("register");
                                  }}
                                >
                                  {/* {t(`login_form.Login`)} */}
                                  {t(`header-footer-menu.signup`)}
                                </div>
                              )}

                              {/* {memberBool === "guestCheckout" && (
                                <div
                                  className={`w-100 tabs py-2 ${memberBool === "guestCheckout" && "active"
                                    }`}
                                  onClick={() => {
                                    setMemberBool("guestCheckout");
                                  }}
                                >
                                  {t("Guest Checkout")}
                                </div>
                              )} */}
                            </div>}
                            <div
                              className={`w-100 login-popup ${memberBool === "login" ? "d-block" : "d-none"
                                }`}
                            >
                              <div className="w-100 user-form">
                                <label className="mb-0">
                                  <span>
                                    {t("login_form.placeholders.email-mobile")}
                                  </span>
                                </label>
                                <div className="d-flex">
                                  {isNumber && (
                                    <CountryCodeDropdown
                                      className="country_code_drop mr-2"
                                      onSelectCountry={({
                                        country_id,
                                        country_mob_code,
                                      }) => {
                                        setCountryId(country_id);
                                        setCountryMobCode(country_mob_code);
                                      }}
                                    />
                                  )}
                                  <input
                                    type="text"
                                    placeholder={t(`Enter_email_or_mobile`)}
                                    value={loginField}
                                    onChange={(e) =>
                                      setLoginField(e.target.value)
                                    }
                                    onKeyPress={(e) => {
                                      e.key === 'Enter' && e.preventDefault()
                                    }}
                                  />
                                </div>


                                <p className="error">
                                  {(loginSubmitted || loginSubmittedViaOTP) &&
                                    loginField === "" &&
                                    t("field_required")}
                                </p>

                                <label className="position-relative mb-0">
                                  <span>
                                    {t("login_form.Password")}
                                  </span>                                      
                                  <input
                                    type={
                                      showLoginPassword ? "text" : "password"
                                    }
                                    autocomplete="new-password"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your password"
                                    )}
                                    value={loginPassword}
                                    min="4"
                                    onChange={(e) =>
                                      setLoginPassword(e.target.value)
                                    }
                                    onKeyPress={(e) => {
                                      e.key === 'Enter' && e.preventDefault()
                                    }}
                                  />
                                  {!showLoginPassword && (
                                    <img
                                      width="30"
                                      height="30"
                                      className="show-password-icon"
                                      src={showPasswordIcon}
                                      onClick={() =>
                                        setShowLoginPassword((x) => !x)
                                      }
                                      alt=""
                                    />
                                  )}
                                  {showLoginPassword && (
                                    <img
                                      width="30"
                                      height="30"
                                      className="show-password-icon"
                                      src={hidePasswordIcon}
                                      onClick={() =>
                                        setShowLoginPassword((x) => !x)
                                      }
                                      alt=""
                                    />
                                  )}
                                    </label>
                                <p className="error">
                                  {loginSubmitted &&
                                    loginPassword === "" &&
                                    type === "email" &&
                                    t(
                                      "login_form.error_messages.Password is required"
                                    )}
                                </p>


                                {loginError &&
                                  loginPassword !== "" &&
                                  type === "email" ? (
                                  <p className="error">
                                    {t(loginError)},{" "}
                                    <span
                                      style={{ cursor: `pointer` }}
                                      onClick={onErrorHandling}
                                    >
                                      {loginError ===
                                        "Password is incorrect" ? (
                                        <u>
                                          {t(
                                            `please click here to change password`
                                          )}
                                        </u>
                                      ) : (
                                        <u>
                                          {t(`please click here to sign up`)}
                                        </u>
                                      )}
                                    </span>
                                  </p>
                                ) : null}
                                {loginOtpError && type === "otp" ? (
                                  <p className="error">
                                    {t(loginOtpError)},{" "}
                                    <span
                                      style={{ cursor: `pointer` }}
                                      onClick={() => {
                                        setMemberBool("register");
                                        setLoginSubmitted(false);
                                      }}
                                    >
                                      <u>{t("please click here to sign up")}</u>
                                    </span>
                                  </p>
                                ) : null}

                                <button
                                  type="button"
                                  onClick={onSubmitLogin}
                                  className={`payment-submit-btn btn-main mt-3 ${loading && "loader-text"
                                    }`}
                                >
                                  {t("login_form.Login")}
                                </button>
                              </div>

                              <div className="login-popup__actions">
                                <button
                                  className="btn-basic"
                                  onClick={onClickForgotPassword}
                                >
                                  {t(`login_form.forgot-password`)}
                                </button>

                                <button
                                  onClick={onClickLoginViaOTP}
                                  className={`btn-basic ${loadingOtp && "loader-text"
                                    }`}
                                >
                                  {t(`Login via OTP`)}
                                </button>
                              </div>
                              <div className="mt-3">
                                <p
                                  className="text-center not_a_member"
                                  onClick={() => setMemberBool("register")}
                                >
                                  {t("Not a member yet")}?{" "}
                                  <a href="#">
                                    {t(`header-footer-menu.signup`)}
                                  </a>
                                </p>
                              </div>
                            </div>

                            {/* Login Via OTP */}
                            <div
                              className={`w-100 login-popup ${memberBool === "otp-login"
                                ? "d-block"
                                : "d-none"
                                }`}
                            >
                              <LoginOtp
                                allowResendLogin={allowResendLogin}
                                setAllowResendLogin={setAllowResendLogin}
                                onClickResendLogin={onClickResendLogin}
                                onSuccess={() => setMemberBool("login-success")}
                                closeFunc={() => setMemberBool("login")}
                              />
                            </div>

                            <div
                              className={`w-100 login-popup ${memberBool === "forgot-password"
                                ? "d-block"
                                : "d-none"
                                }`}
                            >
                              <ForgotPasswordOtp
                                onClickResend={onClickResend}
                                allowResend={allowForgotPasswordResend}
                                setAllowResend={setAllowForgotPasswordResend}
                                field={fpField}
                                setField={setFpField}
                                onSuccess={() =>
                                  setMemberBool("forgot-password-success")
                                }
                                closeFunc={() => setMemberBool("login")}
                              />
                            </div>

                            {/* ) : ( */}
                            <div
                              className={`w-100 ${memberBool === "register" ? "d-block" : "d-none"
                                }`}
                            >
                              <div>
                                <label className="">
                                  <span>{t("login_form.Email")}</span>
                                </label>
                                <input
                                  ref={emailRegisterInput}
                                  type="email"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your email"
                                  )}
                                  value={registerEmail}
                                  onChange={(e) =>
                                    setRegisterEmail(e.target.value)
                                  }
                                />

                                {/* {submitted && registerEmail === "" && ( */}
                                <p className="error">
                                  {submitted &&
                                    registerEmail === "" &&
                                    t(
                                      "login_form.error_messages.Email is required"
                                    )}
                                </p>
                                {/* )} */}
                              </div>
                              <label>
                                <span>
                                  {t("login_form.Phone Number")}
                                </span>
                              </label>
                              <div className="d-flex">
                                <CountryCodeDropdown
                                  className="form-control mr-2"
                                  onSelectCountry={({
                                    country_id,
                                    country_mob_code,
                                  }) => {
                                    setCountryId(country_id);
                                    setCountryMobCode(country_mob_code);
                                  }}
                                />
                                <input
                                  ref={mobileRegisterInput}
                                  type="number"
                                  min="9"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your phone number"
                                  )}
                                  value={registerMobile}
                                  onChange={(e) =>
                                    setRegisterMobile(e.target.value)
                                  }
                                />
                                {/* {submitted && registerMobile === "" && ( */}

                                {/* )} */}
                              </div>

                              <p className="error">
                                {submitted &&
                                  registerMobile === "" &&
                                  t(
                                    "login_form.error_messages.Phone number is required"
                                  )}
                              </p>

                              <div className="w-100">
                                <label class="w-100">
                                  <span>
                                    {t("login_form.DateOfBirth")}
                                  </span>
                                </label>
                                <div className="w-100 position-relative">
                                  <input
                                    className="form-control"
                                    type="date"
                                    id="start"
                                    name="dateofbirth"
                                    placeholder={t("login_form.DateOfBirth")}
                                    min="1920-01-01"
                                    max={moment().format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                      setSelectedDate(e?.target?.value);
                                    }}
                                  />
                                </div>

                                <p className="error">
                                  {submitted &&
                                    !selectedDate &&
                                    dobError &&
                                    t("Please enter event date")}
                                </p>

                              </div>

                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 pr-lg-2">
                                  <label>
                                    <span>{t("login_form.First Name")}</span>
                                  </label>
                                  <input
                                    ref={fNameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your first name"
                                    )}
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />

                                  {/* {submitted && firstName === "" && ( */}
                                  <p className="error">
                                    {submitted &&
                                      firstName === "" &&
                                      t(
                                        "login_form.error_messages.First name is required"
                                      )}
                                  </p>
                                  {/* )} */}
                                </div>
                                <div className="col-lg-6 pl-lg-2">
                                  <label>
                                    <span>  {t("login_form.Last Name")}</span>
                                  </label>
                                  <input
                                    ref={lnameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your last name"
                                    )}
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />

                                  {/* {submitted && lastName === "" && ( */}
                                  <p className="error">
                                    {submitted &&
                                      lastName === "" &&
                                      t(
                                        "login_form.error_messages.Last name is required"
                                      )}
                                  </p>
                                  {/* )} */}
                                </div>
                              </div>
                              <label>
                                <span> {t("login_form.Password")}</span>
                              </label>
                              <div className="position-relative">

                                <input
                                  ref={passwordRef}
                                  type={
                                    showSignupPassword ? "text" : "password"
                                  }
                                  autocomplete="new-password"
                                  className="form-control mb-0"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your password"
                                  )}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                {!showSignupPassword && (
                                  <img
                                    width="30"
                                    height="30"
                                    className="show-password-icon show-password-icon-2"
                                    src={showPasswordIcon}
                                    onClick={() =>
                                      setShowSignupPassword((x) => !x)
                                    }
                                    alt=""
                                  />
                                )}
                                {showSignupPassword && (
                                  <img
                                    width="30"
                                    height="30"
                                    className="show-password-icon show-password-icon-2"
                                    src={hidePasswordIcon}
                                    onClick={() =>
                                      setShowSignupPassword((x) => !x)
                                    }
                                    alt=""
                                  />
                                )}
                                {/* {submitted && password === "" && ( */}

                                {/* )} */}
                                <p className="error">
                                  {submitted &&
                                    password === "" &&
                                    t(
                                      "login_form.error_messages.Password is required"
                                    )}
                                </p>
                              </div>

                              <div className="agree-check mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  checked={registerConditionsCheck}
                                  onChange={(e) =>
                                    setRegisterConditionsCheck(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label pt-1 pl-1"
                                  for="exampleRadios1"
                                >
                                  {/* {t("I agree to all Terms & Conditions")} */}
                                  {t("I agree to all")}{" "}
                                  <a href="/tnc" target="_blank">
                                    {t("Terms & Conditions")}
                                  </a>
                                </label>
                                {/* {registerConditionsCheckError && ( */}

                                {/* )} */}
                                {/* {registerError && <p>{registerError}</p>} */}
                              </div>
                              <p className="error">
                                {submitted && !registerConditionsCheck && (
                                  <>
                                    {t(
                                      "You must agree to all Terms & Conditions"
                                    )}
                                    <br /> <br />
                                  </>
                                )}
                                {registerError && <>{t(registerError)}</>}
                              </p>
                              {/* <p className="error">
                            </p> */}
                              {/* <div>
                              <button
                                className={`payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                              >
                                {t("Continue")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div
                              className="register-btn w-75 mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button className="payment-submit-btn">
                                <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                />
                                {t("Login")}
                              </button>
                            </div> */}
                              <div>
                                <button
                                  className={`payment-submit-btn btn-main ${registerLoading && "loader-text"
                                    }`}
                                  type="submit"
                                >
                                  {t("Continue")}
                                </button>
                              </div>
                            </div>
                            <div
                              className={`w-100 ${memberBool === "guestCheckout"
                                ? "d-block"
                                : "d-none"
                                }`}
                            >
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 pr-lg-2">
                                  <label><span>{t("login_form.First Name")}</span> </label>
                                  <input
                                    ref={fNameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your first name"
                                    )}
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />
                                  <p className="error">
                                    {submitted &&
                                      firstName === "" &&
                                      t(
                                        "login_form.error_messages.First name is required"
                                      )}
                                  </p>
                                </div>
                                <div className="col-lg-6 pl-lg-2">
                                  <label><span>{t("login_form.Last Name")}</span> </label>
                                  <input
                                    ref={lnameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "login_form.placeholders.Enter your last name"
                                    )}
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                  <p className="error">
                                    {submitted &&
                                      lastName === "" &&
                                      t(
                                        "login_form.error_messages.Last name is required"
                                      )}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <label><span>{t("login_form.Email")} </span></label>
                                <input
                                  ref={emailRegisterInput}
                                  type="email"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your email"
                                  )}
                                  value={registerEmail}
                                  onChange={(e) =>
                                    setRegisterEmail(e.target.value)
                                  }
                                />

                                <p className="error">
                                  {submitted &&
                                    registerEmail === "" &&
                                    t(
                                      "login_form.error_messages.Email is required"
                                    )}
                                </p>
                              </div>

                              <div>
                                <label><span>{t("Confirm Email")}</span> </label>
                                <input
                                  ref={confirmemailRegisterInput}
                                  type="email"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your email"
                                  )}
                                  value={confirmregisterEmail}
                                  onChange={(e) =>
                                    setconfirmRegisterEmail(e.target.value)
                                  }
                                />

                                <p className="error">
                                  {submitted &&
                                    emailNotMatched &&
                                    t("Email did not matched")}
                                </p>
                              </div>

                              <label><span>
                                {t("login_form.Phone Number")}</span> </label>
                              <div className="d-flex">
                                <CountryCodeDropdown
                                  className="form-control mr-2"
                                  onSelectCountry={({
                                    country_id,
                                    country_mob_code,
                                  }) => {
                                    setCountryId(country_id);
                                    setCountryMobCode(country_mob_code);
                                  }}
                                />
                                <input
                                  ref={mobileRegisterInput}
                                  type="number"
                                  min="9"
                                  className="form-control"
                                  placeholder={t(
                                    "login_form.placeholders.Enter your phone number"
                                  )}
                                  value={registerMobile}
                                  onChange={(e) =>
                                    setRegisterMobile(e.target.value)
                                  }
                                />
                                {/* {submitted && registerMobile === "" && ( */}

                                {/* )} */}
                              </div>
                              <p className="error"></p>

                              <div className="agree-check mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  checked={registerConditionsCheck}
                                  onChange={(e) =>
                                    setRegisterConditionsCheck(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label pt-1 pl-1"
                                  for="exampleRadios1"
                                >
                                  {/* {t("I agree to all Terms & Conditions")} */}
                                  {t("I agree to all")}{" "}
                                  <a href="/tnc" target="_blank">
                                    {t("Terms & Conditions")}
                                  </a>
                                </label>
                                {/* {registerConditionsCheckError && ( */}

                                {/* )} */}
                                {/* {registerError && <p>{registerError}</p>} */}
                              </div>
                              <p className="error">
                                {submitted && !registerConditionsCheck && (
                                  <>
                                    {t(
                                      "You must agree to all Terms & Conditions"
                                    )}
                                    <br /> <br />
                                  </>
                                )}
                                {registerError && <>{t(registerError)}</>}
                              </p>
                              <div>
                                <button
                                  className={`payment-submit-btn btn-main ${registerLoading && "loader-text"
                                    }`}
                                  type="submit"
                                >
                                  {t("Guest Checkout")}
                                </button>
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </form>
                        {/* <div className="next-step">
                          <p>Next Step</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                      </>
                    )}

                    {/* <PaymentOtp /> */}
                    {/* </> */}
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </Card>
                {/* {loggedInUser && loggedInConditionsCheck && isConfirmed && ( */}
                <Card
                  className={``}
                >
                  <Accordion.Toggle
                    as={Card.Header}
                    
                    eventKey={getStepWithOffset("1", GUEST_CHECKOUT)}
                    onClick={(e) => {
                      if (bookingSuccessful === true) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }
                      loggedInUser && isMobile
                        ? toggleHandle(2)
                        : toggleHandle(1);
                    }}
                    className={
                      `${(isMobile
                        ? open[getStepWithOffset(2, GUEST_CHECKOUT)]
                        : open[getStepWithOffset(1, GUEST_CHECKOUT)]
                      )
                        ? "active"
                        : ""} ${!(loggedInUser && loggedInConditionsCheck && isConfirmed) &&
                    "inactive"
                    }`
                    }
                  >
                    {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "3" : "2", GUEST_CHECKOUT)
                    }</div> */}
                    <article>
                      
                      <h2>
                        <span>{
                      getStepWithOffset(isMobile ? "3" : "2", GUEST_CHECKOUT)
                    }.&nbsp;</span>{t("Choose Payment Mode")}</h2>
                    </article>
                  </Accordion.Toggle>
                  {/* {defaultTab == "1" ? ( */}
                  <Accordion.Collapse
                    eventKey={getStepWithOffset(isMobile ? "2" : "1", GUEST_CHECKOUT)}
                    id="collapse-padding"
                  >
                    <div className="booking_accordion_step">
                      <PaymentSelectMode
                        email={email}
                        category_id={category_id}
                        toggleHandle={toggleHandle}
                        onConfirmPayment={onConfirmPayment}
                        t={t}
                        mobile={mobile}
                        countryMobCode={countryMobCode}
                      />
                    </div>
                    {/* <>
                    <PaymentCardDetails />
                  </> */}
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </Card>
                {/* // )} */}
                {/* {loggedInUser &&
                  isConfirmed &&
                  bookingSuccessful === true &&
                  loggedInConditionsCheck && ( */}
                <Card
                  className={` booking-success-accordian `}
                >
                  <Accordion.Toggle
                    as={Card.Header}
                    
                    eventKey={getStepWithOffset("2", GUEST_CHECKOUT)}
                    // onClick={() => {
                    //   isMobile ? toggleHandle(3) : toggleHandle(2);
                    // }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={`${
                      (isMobile
                        ? open[getStepWithOffset(3, GUEST_CHECKOUT)]
                        : open[getStepWithOffset(2, GUEST_CHECKOUT)]
                      )
                        ? "active"
                        : ""
                    } 
                    ${!(
                    loggedInUser &&
                    isConfirmed &&
                    bookingSuccessful === true &&
                    loggedInConditionsCheck
                  ) && "inactive"
                    }`}
                  >
                    {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "4" : "3", GUEST_CHECKOUT)
                    }</div> */}
                    <article>
                      
                      <h2>
                        <span>
                      {
                      getStepWithOffset(isMobile ? "4" : "3", GUEST_CHECKOUT)
                    }.&nbsp;
                    </span>{t("Confirmation")}</h2>
                    </article>
                  </Accordion.Toggle>
                  {/* {defaultTab == "2" ? ( */}
                  <Accordion.Collapse
                    eventKey={getStepWithOffset(isMobile ? "3" : "2", GUEST_CHECKOUT)}
                    id="collapse-padding"
                  >
                    <div className="booking_accordion_step">
                      {/* <PaymentConfirmMessage t={t} /> */}
                      <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
                        {/* <div> */}
                        {/* <h4 className="font-weight-bold">Pay ₹{totalAmount}</h4> */}
                        {/* <p className="grey-font">Transaction #1234567889</p> */}
                        {/* </div> */}
                        <div className="">
                         <img
              src={paymentSuccessIcon}
              alt="Payment Success"
              className=""
            />
                        </div>
                        <div className="pt-2">
                          <h2 className="font-weight-bold">
                             {t("Enjoy your movie!")}
                          </h2>
                          <p className="">
                             {t("Your transaction was successfully processed, check your email for your tickets")}
                          </p>
                        </div>
                        <div className="mb-0">
                          <button
                            className="payment-submit-btn"
                            onClick={() => {
                              console.log({ isUnpaidBooking });
                              if (isUnpaidBooking)
                                history.push(
                                  `/unpaid-ticket/${reservation_id}/${category_id}`
                                );
                              else
                                history.replace(
                                  `/final-ticket/${showBookingID}/${category_id}`
                                );
                            }}
                          >
                             {t("Close and collect ticket")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </Card>
                {/* )} */}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <MovieFooter
        movie_title={scheduleDetails?.movie_title}
        image={scheduleDetails?.md_thumbnail_url}
        lang={scheduleDetails?.lang_name}
        format={scheduleDetails?.mf_name}
        rating={scheduleDetails?.rating}
        genre={scheduleDetails?.g_name}
        cinemaName={scheduleDetails?.cine_name}
        cine_location={scheduleDetails?.cine_location}
        cine_address={scheduleDetails?.cine_address}
        date={scheduleDetails?.ss_actual_start_date_time}
        time={scheduleDetails?.ss_actual_start_date_time}
        totalAmount={totalAmount}
        curr_code={curr_code}
        screeningType={
          booking_type == 1
            ? "Confirmed Screening"
            : booking_type == 2
            ? "Crowdsourced Screening"
            : booking_type == 3
            ? "Private Screening"
            : null
        }
        showType={
          show?.showType == 2
            ? "Unconfirmed"
            : show?.showType == 1
            ? "Confirmed"
            : null
        }
        category_id={category_id}
        booking_type={booking_type}
      /> */}
    </>
  );
};
// const stripePublisherKey = useSelector(
//   (state) => state.movies.stripePublisherKey
// );

// Start  Payment Select Mode
const PaymentSelectMode = ({
  email,
  category_id,
  toggleHandle,
  onConfirmPayment,
  t,
  mobile,
  countryMobCode,
}) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  const { reservation_id } = useParams();
  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, guestUser } = loginDetails;
  const isSaferPayActive = useSelector(
    (state) => state.movies.isSaferPayActive
  );
  const isSaferPayButtonDisable = useSelector(
    (state) => state.movies.isSaferPayButtonDisable
  );
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [showStripe, setShowStripe] = useState(false);
  const [is100PercentOff, setIs100PercentOff] = useState(false);
  const appliedVoucher = useSelector(
    (state) => state.promotions.applied_voucher
  );
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const reservationDetails = useSelector(
    (state) => state.movies?.reservationDetails
  );
  const booking_type_id = reservationDetails?.booking_type_id ?? null;

  const makeUnpaidBooking = () => {
    console.log({ booking_type_id });
    setUnpaidBookingLoading(true);
    dispatch(
      unpaidBooking({
        reservation_id,
        cust_id: loggedInUser?.cust_id,
        cinema_id: scheduleDetails.cinema_id,
        category_id,
        callback: setUnpaidBookingLoading,
        secondCallback: toggleHandle,
        booking_type_id,
      })
    );
    // history.push(`/unpaid-ticket/${reservation_id}/${category_id}`);
  };

  const MakeSaferPayPayment = () => {
    setSaferPayLoading(true);
    let payload = {
      reservation_id,
      cust_id: loggedInUser?.cust_id ?? 0,
      guest_user_id: guestUser?.guest_user_id ?? 0,
      email,
      cinema_id: scheduleDetails.cinema_id,
      category_id,
      callback: setSaferPayLoading,
      secondCallback: toggleHandle,
      cinema_name: scheduleDetails.cine_name,
      mobile,
      countryMobCode,
    };
    let appliedCashCard = {
      usedCashCards: [],
      isCashCardActive: true,
      CashCardDetails: {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        isStreetSale: firstCashCard?.is_street_sale,
        type: "2",
        amount: cashCardRedemptionSelection?.amount,
        cardAmount: cashCardRedemptionSelection?.amount
      },
      isCashCardTransaction: true,
    };
    if (cashCardRedemptionSelection?.amount)
      payload.appliedCashCard = appliedCashCard
    setShowStripe(false);
    dispatch(makeSaferPayPayment(payload));
  };

  const MakeOfferPayment = () => {
    setOfferpaymentLoading(true);
    console.log("Offer payment");

    dispatch(
      makeOfferPaymentBooking({
        reservation_id,
        cust_id: loggedInUser?.cust_id,
        email,
        cinema_id: scheduleDetails.cinema_id,
        category_id,
        callback: setOfferpaymentLoading,
        secondCallback: toggleHandle,
        cinema_name: scheduleDetails.cine_name,
        mobile,
        countryMobCode,
        payment_mode_id: 13, // payment_mode_id = 13 = Vouchers applied
        booking_type_id,
      })
    );
  };
  const onApplyPromoCode = () => {
    setRedemptionValue('')
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' })
    setValidGiftCardCredentials(false)
    // setShowCashCardSection(false)

    setIs100PercentOff(false);

    if (!promoCode || promoCode.length === 0) {
      setPromoCodeError('Invalid Promo Code');
      // toast.dismiss();
      // toast.error("Please enter a promo code");
      return;
    }
    setPromoCodeError(null);

    let voucher = {
      cinema_id: scheduleDetails.cinema_id,
      cinema_name: scheduleDetails.cine_name,
      isTicket: true,
      md_id: scheduleDetails.md_id,
      net_amount: reservationDetails?.net_amount,
      total_amount: reservationDetails?.amount,
      total_ticket: scheduleDetails?.selectedSeats?.length,
      voucher_title: promoCode,
      voucher_code: promoCode,
    };

    console.log(voucher);

    dispatch(validateVoucherCode(voucher))
      .then((response) => {
        console.log("response :>> ", response);
        let { is100PercentOff } = response;
        setIs100PercentOff(is100PercentOff);
        if (response && response.status === false) {
          setPromoCodeError(response.message);
        }
      })
      .catch((err) => {
        setIs100PercentOff(false);
        console.log("error applying voucher", err);
        setPromoCodeError(err.message);
      });
  };

  const [loading, setLoading] = useState(false);
  const [loadingOfferpayment, setOfferpaymentLoading] = useState(false);
  const [SaferPayloading, setSaferPayLoading] = useState(false);
  const [UnpaidBookingLoading, setUnpaidBookingLoading] = useState(false);

  const [stripeError, setStripeError] = useState(null);
  const dispatch = useDispatch();
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  let isPayBtnClick = useRef(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isPayBtnClick.current === true) {
      return;
    }

    // await stripe.create;
    isPayBtnClick.current = true;
    const response = await stripe.createToken(
      elements.getElement(CardElement),
      {
        currency: curr_code,
        account_holder_name: loggedInUser?.first_name,
        account_holder_type: "individual",
      }
    );
    console.log("response :>> ", response);

    if (response.token && !response.token.used) {
      setStripeError(null);
      setLoading(true);
      console.log(`reservation_id`, reservation_id);
      let voucher = {
        cinema_id: scheduleDetails.cinema_id,
        cinema_name: scheduleDetails.cine_name,
        isTicket: true,
        md_id: scheduleDetails.md_id,
        net_amount: reservationDetails?.net_amount,
        total_amount: reservationDetails?.amount,
        total_ticket: scheduleDetails?.selectedSeats?.length ?? 0,
        voucher_title: promoCode,
        voucher_code: promoCode,
      };
      let appliedCashCard = {
        usedCashCards: [],
        isCashCardActive: true,
        CashCardDetails: {
          cash_card_number: firstCashCard?.cash_card_number,
          customer_email: firstCashCard?.cust_email,
          isStreetSale: firstCashCard?.is_street_sale,
          type: "2",
          amount: cashCardRedemptionSelection?.amount,
          cardAmount: cashCardRedemptionSelection?.amount
        },
        isCashCardTransaction: true,
      };
      if (timerCompleted === false) {
        dispatch(
          category_id == 1
            ? onSuccessStripeToken({
              reservation_id,
              token: response.token,
              cust_id: loggedInUser?.cust_id,
              email,
              cinema_id: scheduleDetails.cinema_id,
              category_id,
              callback: setLoading,
              secondCallback: toggleHandle,
              cinema_name: scheduleDetails.cine_name,
              mobile,
              countryMobCode,
              appliedVoucher: !appliedVoucher ? null : voucher,
              appliedCashCard: !!cashCardRedemptionSelection?.amount ? appliedCashCard : {},
            })
            : onSuccessStripeTokenBrij({
              reservation_id,
              token: response.token,
              cust_id: loggedInUser?.cust_id,
              email,
              cinema_id: scheduleDetails.cinema_id,
              category_id,
              callback: setLoading,
              secondCallback: toggleHandle,
              cinema_name: scheduleDetails.cine_name,
              mobile,
              countryMobCode,
              appliedVoucher: !appliedVoucher ? null : voucher,
              appliedCashCard: !!cashCardRedemptionSelection?.amount ? appliedCashCard : {},
            })
        );
      }

      // onConfirmPayment({stripe:true,})
    } else {
      isPayBtnClick.current = false;
      setStripeError(response.error.message);
      return;
    }
  };
  const onRemoveVoucher = () => {
    setIs100PercentOff(false);
    dispatch(clearSelectedVoucher());
  };
  const bookSeatError = useSelector((state) => state.movies.bookSeatError);
  const activePaymentModes = useSelector(
    (state) => state.movies.activePaymentModes
  );

  let isStripePaymentModeEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 14)?.length > 0 ?? false;

  let isUnpaidBookingEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 17)?.length > 0 ?? false;

  let ActivePaymentModeAvailable =
    activePaymentModes?.filter(
      (p) => p?.pm_id == 17 || p?.pm_id == 15 || p?.pm_id == 14
    )?.length > 0 ?? false;

  useEffect(() => {
    return () => {
      console.log("PaymentSelectMode unmounted");
      onRemoveVoucher();
      setRedemptionValue('')
      dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' })
      setValidGiftCardCredentials(false)
    };
  }, []);


  const [showCashCardSection, setShowCashCardSection] = useState(false);
  const [validGiftCardCredentials, setValidGiftCardCredentials] = useState(false);
  const [redemptionAmount, setRedemptionAmount] = useState("");

  const [firstCashCard, setFirstCashCard] = useState({});
  const [fullCashCardaymentLoading, setfullCashCardaymentLoading] = useState(false);

  let [submittedCashCardDetails, setSubmittedCashCardDetails] = useState(null);
  let [showRedeemTab, setShowRedeemTab] = useState(true);

  let [card, setCard] = useState("");
  let [cardError, setCardError] = useState(false);

  let [pin, setPin] = useState("");
  let [pinError, setPinError] = useState(false);
  let [errorMsg, setErrorMsg] = useState("");

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector((state) => state.cashCard.cashCardDetailsLoading);
  const cashCardTopUpSelection = useSelector((state) => state.cashCard.cashCardTopUpSelection);
  const cashCardRedemptionSelection = useSelector((state) => state.cashCard.cashCardRedemptionSelection);

  let submitCashCardDetails = () => {
    setErrorMsg("");
    setCardError(false);
    setPinError(false);
    setSubmittedCashCardDetails(true);

    let count = 0;
    if (!card) {
      ++count;
      setCardError(true);
    }
    if (!pin) {
      ++count;
      setPinError(true);
    }

    if (count > 0) {
      console.log({ pin, card });
      return;
    }

    dispatch(fetchCashCardDetailsFromCardNumberPin({ cash_card_number: card, cash_pin: pin }))
    .then(({ status, message }) => {
      if (!!status) {
        // history.push(`/cash-card-topup/${card}`);
        setTimeout(() => {
          setValidGiftCardCredentials(true);
        }, 100);
      } else {
        setCardError(true);
        setErrorMsg(message);
      }
    })
    .catch(console.log)
    .finally(() => {
    });
  };

  const [redemptionAmountEqGrandTotal, setRedemptionAmountEqGrandTotal] = useState(false);
  useEffect(() => {
    if (
      cashCardRedemptionSelection?.amount &&
      cashCardRedemptionSelection?.amount == (
        (reservationDetails?.amount || 0) +
        (fnbPrice?.total_price || 0) -
        (discount ? discount : 0)
      )
    ){
      setRedemptionAmountEqGrandTotal(true)
    }
    else {
      setRedemptionAmountEqGrandTotal(false)
    }
  }, [discount, fnbPrice, redemptionAmount, reservationDetails, cashCardRedemptionSelection])

  let setRedemptionValue = (amount) => {
    setRedemptionAmount(amount)
    // dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: amount })
  }

  const fullCashCardpayment = () => {
    setfullCashCardaymentLoading(true);

    let appliedCashCard = {
      usedCashCards: [],
      isCashCardActive: true,
      CashCardDetails: {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        isStreetSale: firstCashCard?.is_street_sale,
        type: "2",
        amount: cashCardRedemptionSelection?.amount,
        cardAmount: cashCardRedemptionSelection?.amount
      },
      isCashCardTransaction: true,
      payment_mode: {
        "bs_id": 1,
        "bs_source": "Box Office",
        "bs_is_active": "Y",
        "created_by": null,
        "created_at": null,
        "updated_by": 1,
        "updated_at": "2022-01-05T15:10:00.000Z",
        "bspm_id": 11,
        "booking_source_id": 1,
        "payment_mode_id": 11,
        "mapping_is_active": "Y",
        "pm_id": 11,
        "pm_payment_mode": "Cash Card",
        "pm_icon_url": null,
        "pm_is_active": "Y",
        "amount": 0
      }
    };

    dispatch(
      makeFullCashCardpayment({
        reservation_id,
        cust_id: loggedInUser?.cust_id,
        email,
        cinema_id: scheduleDetails.cinema_id,
        category_id,
        callback: setfullCashCardaymentLoading,
        secondCallback: toggleHandle,
        cinema_name: scheduleDetails.cine_name,
        mobile,
        countryMobCode,
        payment_mode_id: 11, // payment_mode_id = 13 = CashCard
        booking_type_id,
        appliedCashCard
      })
    );
  };

  const onRemoveCashCardRedeem = () => {
    setRedemptionValue('')
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' })
    setValidGiftCardCredentials(false)
    setShowRedeemTab(true)
  }
  const onApplyCashCardRedeem = () => {
    onRemoveVoucher()
    setShowRedeemTab(false)
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: redemptionAmount })
  }

  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length) setFirstCashCard(cashCardDetails[0]);
  }, [cashCardDetails]);

  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length) {
      let valueToSet = ''
      let grandTotal = (reservationDetails?.amount || 0) + (fnbPrice?.total_price || 0)
      let cardValue = cashCardDetails?.[0]?.balance_amount ?? 0
      if (cardValue < grandTotal)
        valueToSet = cardValue
      else
        valueToSet = grandTotal

      setRedemptionValue(valueToSet)
    }
  }, [cashCardDetails, reservationDetails, fnbPrice]);



  return (
    <>
      {/* <div className="payment-mode col-md-10 col-lg-9 col-xl-7 px-0 mx-auto pt-3">
        <div>
          <img src={wallet} alt="wallet" />
          <h6
            className="text"
            onClick={() => loggedInUser && onConfirmPayment({ stripe: false })}
          >
            My Cinema Wallet
          </h6>
        </div> */}

      {/* <div>
          <img src={creditCard} alt="credit-card" />
          <h6 className="text">Credit / Debit Card</h6>
        </div> */}
      {/* <div>
          <img src={netBanking} alt="net-banking" />
          <h6 className="text">Netbanking</h6>
        </div> */}
      {/* <div>
          <img src={aliPay} alt="Ali Pay" className="grey-arrow-down" />
          <h6 className="text">Ali Pay</h6>
        </div>
      </div> */}
      {!showCashCardSection && (<>
      <div
        className={`promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto ${appliedVoucher ? "d-flex justify-content-center align-items-center" : ""
          }`}
      >
        {!appliedVoucher ? (
          <div>
          <p>
            {t('Do you have a voucher or promo code?')}
        </p>
            <div className="promo_code_wrapper">
              <input
              type="text"
              className="form-control"
              placeholder={t("Apply your promo code here")}
              value={promoCode || ""}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button onClick={() => onApplyPromoCode()} className="btn-main guest_btn">
              {t("Apply")}
            </button>
            </div>
            {promoCodeError && (
              <p className="error mt-1"> {t(promoCodeError)}</p>
            )}
          </div>
        ) : (
          <>
            <p>
              {t("Voucher Applied")}: {appliedVoucher?.voucher_title}
            </p>
            <button
              onClick={onRemoveVoucher}
              className="promo-btn remove-code ml-2"
            >
              {t("Remove")}
            </button>
          </>
        )}
      </div>
      </>)}

      {!showCashCardSection && !appliedVoucher && (<>
        <div className="mt-3 mb-3 gift_card_redemption_btn_group">
          <button className="btn-main" onClick={() => {
            setShowCashCardSection(true);
          }}>
            {t("Apply Cash Card")}
          </button>
        </div>
      </>)}

      {showCashCardSection && !appliedVoucher && (<>
        {showRedeemTab && (
          <div className="promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto my-3">
            {validGiftCardCredentials ?
              <div className="l-border_">
                <h6 className="mt-3 gift_card_redemption_balance">
                  {t("Your Current Balance is")}
                  <p>{curr_code}{" "}{currencyFormatter(firstCashCard?.balance_amount)}</p>
                </h6>
                <p>
                  {t("Enter amount to be reduced")}
                </p>
                <input
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter amount to be reduced"
                  value={redemptionAmount}
                  onChange={(e) => {
                    if (!firstCashCard?.balance_amount) return;
                    setRedemptionValue(
                      Number.isNaN(parseInt(e.target.value, 10))
                        ? ''
                        : parseInt(e.target.value, 10)
                    );
                    if (Number.isNaN(parseInt(e.target.value, 10))){
                      dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' })
                    }
                  }}
                />
                {firstCashCard?.balance_amount <= 0 && (
                  <p className="error"> {t("No balance")}</p>
                )}
                {firstCashCard?.balance_amount < redemptionAmount && (
                  <p className="error"> {t("Redeem Amount cant be greater than cash card balance")}</p>
                )}
                {redemptionAmount > (
                  (reservationDetails?.amount || 0) +
                  (fnbPrice?.total_price || 0) -
                  (discount ? discount : 0)
                ) && (
                  <p className="error"> {t("Redeem Amount cant be greater than grand total")}</p>
                )}

                <div className="mb-3 gift_card_redemption_btn_group">
                  <button className="btn-main no_background" onClick={() => {
                    setRedemptionValue('');
                    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' })
                    setValidGiftCardCredentials(false);
                  }}>
                    {t("Back")}
                  </button>
                  <button
                    className={`btn-main ${
                      (
                        !redemptionAmount ||
                        firstCashCard?.balance_amount == 0 ||
                        firstCashCard?.balance_amount < redemptionAmount ||
                        redemptionAmount > (
                          (reservationDetails?.amount || 0) +
                          (fnbPrice?.total_price || 0) -
                          (discount ? discount : 0)
                        )
                      )
                        ? "inactive"
                        : ""
                    }`}
                    onClick={() => {
                      onApplyCashCardRedeem()
                    }}
                  >
                    {t("Apply")}
                  </button>
                </div>
              </div>
              :
              <div className="l-border_">
                <p className="mt-3">
                  {t("Card Number")}
                </p>
                <input
                  type="number"
                  className="form-control mb-1"
                  placeholder={t("Enter your Card number")}
                  value={card}
                  onChange={(e) => {
                    setCard(e?.target?.value);
                  }}
                />
                {submittedCashCardDetails && (cardError || errorMsg) && (
                  <p className="error">
                    {t(errorMsg ? errorMsg : cardError ? "Please enter Card number" : "")}
                  </p>
                )}
                <p className={"mt-3"}>
                  {t("PIN number")}
                </p>

                <input
                  type="number"
                  className="form-control mb-1"
                  placeholder={t("Enter your PIN number")}
                  value={pin}
                  onChange={(e) => {
                    setPin(e?.target?.value);
                  }}
                />

                {submittedCashCardDetails && (cardError || errorMsg) && (
                  <p className="error">
                    {t(errorMsg ? errorMsg : pinError ? "Please enter PIN number" : "")}
                  </p>
                )}
                <div className="mt-3 mb-3 gift_card_redemption_btn_group">
                  <button className="btn-main no_background" onClick={() => {
                    setShowCashCardSection(false)
                  }}>
                    {t("Back")}
                  </button>
                  <button className={`btn-main ${cashCardDetailsLoading && "loader-text inactive"
                  }`} onClick={() => {
                    submitCashCardDetails();
                  }}>
                    {t("Next")}
                  </button>
                </div>
              </div>
            }
          </div>
        )}
        {
          !showRedeemTab && (
            <div className="promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto my-3 d-flex justify-content-center align-items-center">
              <p>
                {t("Cash Card Applied")}: {curr_code}{" "}{currencyFormatter(redemptionAmount)}
              </p>
              <button
                onClick={() => {
                  setShowCashCardSection(false)
                  onRemoveCashCardRedeem()
                }}
                className="promo-btn remove-code ml-2"
              >
                {t("Remove")}
              </button>
            </div>
          )
        }
      </>)}

      {!redemptionAmountEqGrandTotal && (<>
      {!is100PercentOff && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3">
          <article className="payment-methods">
              {isStripePaymentModeEnabled &&
                (isSaferPayActive || isUnpaidBookingEnabled) && (
                  <button
                    className={`btn-main`}
                    onClick={() => setShowStripe(!showStripe)}
                  >
                    {t("Stripe")}
                  </button>
                )}
              {isSaferPayActive && (
                <button
                  className={`btn-main ${SaferPayloading && "loader-text inactive"
                    }`}
                  disabled={isSaferPayButtonDisable}
                  onClick={MakeSaferPayPayment}
                >
                  {t("Safer Pay")}
                </button>
              )}
              {!appliedVoucher &&
               !showCashCardSection &&
                isUnpaidBookingEnabled &&
                booking_type_id === 0 && (
                  <button
                    className={`btn-main ${UnpaidBookingLoading && "loader-text inactive"
                      }`}
                    onClick={() => makeUnpaidBooking()}
                  >
                    {t("Unpaid Booking")}
                  </button>
                )}
            </article>
        </div>
      )}
      </>)}

      {!is100PercentOff &&
        (showStripe || (!isSaferPayActive && !isUnpaidBookingEnabled)) &&
        isStripePaymentModeEnabled && (
          <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3">
            {bookSeatError && <p className="error">{bookSeatError}</p>}
            <form onSubmit={handleSubmit}>
              {stripeKey && (
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "white",
                        "::placeholder": {
                          color: "#eeeeee",
                        },
                        padding: "10px",
                        border: "1px solid white",
                      },
                      invalid: {
                        color: "#dc3545",
                      },
                    },
                  }}
                />
              )}
              {stripeError && (
                <p className="error stripe-error">{t(stripeError)}</p>
              )}
              <button
                className={`btn-main mx-auto mt-3 ${loading && "loader-text inactive"
                  }`}
                disabled={!stripe}
              >
                {t("Pay")}
              </button>
            </form>
          </div>
        )}

      {is100PercentOff && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${loadingOfferpayment && "loader-text inactive"
              }`}
            onClick={() => MakeOfferPayment()}
          >
            {t("Confirm")}
          </button>
        </div>
      )}
      {redemptionAmountEqGrandTotal && !showRedeemTab && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${fullCashCardaymentLoading && "loader-text inactive"
              }`}
            onClick={() => {
              fullCashCardpayment()
            }}
          >
            {t("Confirm")}
          </button>
        </div>
      )}
      {!ActivePaymentModeAvailable && !is100PercentOff && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-2 pb-3 d-flex">
          <p className="error stripe-error w-100 fs-16">
            {t("No Active Payment Modes Found")}
          </p>
        </div>
      )}
      {/* {isUnpaidBookingEnabled && booking_type_id === 0 && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${
              UnpaidBookingLoading && "loader-text inactive"
            }`}
            onClick={() => makeUnpaidBooking()}
          >
            {t("Unpaid Booking")}
          </button>
        </div>
      )} */}
    </>
  );
};
// End  Payment Select Mode

//Start Payment Booking Details
const PaymentBookingDetails = ({ category_id, t }) => {
  const isMobile = useIsMobile()
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const netAmount = useSelector((state) => state.movies.netAmount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const booking_type = useSelector((state) => state.movies.booking_type);
  const appliedVoucher = useSelector(
    (state) => state.promotions.applied_voucher
  );
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const selected_seats = useSelector(
    (state) => state.seatLayout.seats_selected
  );
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;
  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const seatTypes = useSelector((state) => seatTypesSelector(state));

  const is_internet_handling_enable = useSelector(
    (state) => state?.seatLayout?.is_internet_handling_enable
  );
  const internet_handling_fee = scheduleDetails?.internet_handling_fee ?? 0;

  const reservationDetails = useSelector(
    (state) => state.movies.reservationDetails
  );

  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const screeningTypes =
    useSelector((state) => state.movies?.screeningTypes) ?? [];
  let private_rate_card_detail =
    reservationDetails?.private_rate_card_detail ?? [];
  let ticket_count =
    private_rate_card_detail.length > 0
      ? private_rate_card_detail[0].customSeatCount
      : null;
  
  const movieBottomRef = useRef();
  const movieInfoRef = useRef();
  useEffect(() => {
    const setMovieBottomWidth = () => {
      const movieBottom = movieBottomRef.current;
    const movieInfo = movieInfoRef.current;
    if (movieBottom && movieInfo) {
        movieBottom.style.width = movieInfo.offsetWidth + "px";
    }
    }
    setMovieBottomWidth();
    window.addEventListener("resize", setMovieBottomWidth);
    return ()=>window.removeEventListener("resize", setMovieBottomWidth);
  }, [])

  function percentage(percent, total) {
    return ((percent/ 100) * total)
  }

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector((state) => state.cashCard.cashCardDetailsLoading);
  const cashCardRedemptionSelection = useSelector((state) => state.cashCard.cashCardRedemptionSelection);

  return (
    <div className={`payment_details_new movie_info_wrapper ${(!isMobile) ?"sticky_movie_info" : ""}`}>
      {/* <div className="movie-header">
        <div className="position-relative">
          <img
            src={
              getLangSpecificAttribute(
                scheduleDetails?.languageSpecificImages,
                lang_id,
                "artwork"
              ) ||
              scheduleDetails?.md_thumbnail_url ||
              dummyPoster
            }
            alt="Movie Img"
            className="big-image w-100"
          />
          <div className="movie-overlay"></div>
        </div>
      </div> */}
      {isMobile ?
        // MOBILE DESIGN
<>
           <article className="movie_info_mobile" ref={movieInfoRef}>
         <figure className="movie-img">
            <img
              src={
                getLangSpecificAttribute(
                  scheduleDetails?.languageSpecificImages,
                  lang_id,
                  "artwork"
                ) ||
                scheduleDetails?.md_thumbnail_url ||
                dummyPoster
              }
              alt="Movie Img"
            />
            </figure>
            
            <article className="">
              <section>
                 <h6 className="movie_booking_type">{
                   // category_id == 1 ? t('On-demand').toUpperCase() : t("Now Showing").toUpperCase()
                 }</h6>
            <h1 className="movie_info_title">
              {getLangSpecificAttribute(
                scheduleDetails?.altMovieContent,
                lang_id,
                "mc_title"
              ) || scheduleDetails?.movie_title}
              </h1>
               <div className="movie_info_rating_n_format mb-0">
              <span className="movie_info_rating">{scheduleDetails?.rating}</span>
              <div className="movie_info_language"> {
                scheduleDetails?.lang_translation_obj?.[iso_2?.toLowerCase()]
                ?? !!t(scheduleDetails?.lang_name) ? t(scheduleDetails?.lang_name) : scheduleDetails?.lang_name}{`${
                scheduleDetails?.sub_1_iso_2 ? `/${scheduleDetails?.sub_1_iso_2}` : ''
              }${
                scheduleDetails?.sub_1_iso_2 && scheduleDetails?.sub_2_iso_2 ? `-${scheduleDetails?.sub_2_iso_2}` : ''
              }`}
              {" "}</div>
                  <div className="movie_info_language">
                    {scheduleDetails?.mrrdr_runtime} {t("common.mins")}
                  </div>
            </div>
              </section>
            {/* <section className="">
                  <article>
                    <h6 className="">{t("Genre")}</h6>
                <h5 className="">{scheduleDetails?.movie_genres
                  ?.filter((genre) => genre[iso_2?.toLowerCase()])
                  ?.map(
                    (genre, index) =>
                      genre[iso_2?.toLowerCase()] &&
                      index < 13 && (
                        <span key={index}>
                          {genre[iso_2?.toLowerCase()]}
                          {index !== scheduleDetails?.movie_genres?.length - 1 ? ', ' : ''}
                        </span>
                      )
                  )}</h5>
                </article>
                  <article>
                    <h6 className="">{t("Year")}</h6>
                <h5>{moment(
                  scheduleDetails?.original_mrrdr_release_date ===
                    `Invalid Date`
                    ? moment()
                    : scheduleDetails?.original_mrrdr_release_date ||
                    moment()
                ).format("YYYY")}</h5>
                </article>
                  <article>
                    <h6 className="">{t('Duration')}</h6>
                <h5>{scheduleDetails?.mrrdr_runtime} {t("common.mins")}</h5>
                </article>
            </section> */}
              <section>
                <div className=" text-left mb-0">
          <h6 className="">{t("Date")} &amp; {t("Time")}</h6>
          <p>
            {moment(scheduleDetails?.ss_actual_start_date_time).format(
              "ll"
            )}{" "}
            |{" "}
            {moment(scheduleDetails?.ss_start_show_time, "HH:mm:ss").format(
              "HH:mm"
            )}
          </p>
        </div>
        
              </section>
              <section>
                <div className=" text-left mb-0">
            <h6 className="">{t("Cinema")}</h6>
            <p>{scheduleDetails?.screen_name}</p>
          <p> {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}</p>
        </div>
              </section>
          </article>
        
          </article>
          
        <div className="">
          <h6 className="">{t('Booking Summary').toUpperCase()}</h6>
          <article className="payment_details_rows">
              {seatTypes?.length > 0 && seatTypes?.map((seatType, seatTypeIndex) => (
                <>
                  
                  {seatType?.seats?.length > 0 && seatType?.seats?.map((rcg_child, index2) => (
                    <section className="">
                      <p className="left_value">
                        <span className="ml-0">(x{rcg_child.no_of_seats} {t("Seats")})</span>&nbsp;
                        <>
                    {seatType.sst_seat_type}
                  </>
                      <span className="">{rcg_child?.ticket_type_translations?.[
                        iso_2?.toLowerCase()
                      ] || rcg_child.tt_name}</span>
                      </p>
                      
                      <p className="right_value">{curr_code}{" "}{currencyFormatter(rcg_child?.total_price)}</p>
                    </section>
                  ))}
                </>

              ))}
            {scheduleDetails?.booking_type_id == 3 ? (
              <>
                <section className="">
                  <div className="left_value">{scheduleDetails?.screen_name}</div>
                  <div className="right_value">{!!ticket_count
                    ? `(x${ticket_count}):`
                    : `(x${scheduleDetails?.ticket_count}):`}</div>
                </section>
                <section className="">
                  <div className="left_value">{scheduleDetails?.booking_type_name}</div>
                  <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                </section>
              </>
            ) : (
                  <>
                  {/* <section className="">
                <div className="left_value">{scheduleDetails?.seat_name}</div>
                <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                    </section> */}
                  </>
            )}

          </article>
          {/* {selectedFnbItems && selectedFnbItems.length > 0 && (
            <article className="grey-title-lg mb-2">
              {t("Concessions")}
            </article>
          )} */}

         {selectedFnbItems &&
              selectedFnbItems.length > 0 && <div className="payment_details_rows">
              {selectedFnbItems.map((item) => (
                <section>
                  <div className="left_value">{getLangSpecificAttribute(
                    item?.FnbContent,
                    lang_id,
                    "item_lang_name"
                  ) || item.item_name}
                    {item.Quantity && <span>(x{item.Quantity})</span>}</div>
                  <div className="right_value">{curr_code}{" "}
                    {currencyFormatter(item.fp_total_amount)}</div>
                </section>))}
          </div>}

          {
            discount && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t("Discount")}:{" "}</div>
                  <div className="right_value"> {" "}
                    - {curr_code} {currencyFormatter(discount)}</div>
                </section>
              </div>
            )
              }
          {
            !discount &&
            !!cashCardRedemptionSelection?.amount &&
            cashCardRedemptionSelection?.amount <= (
              (reservationDetails?.amount || 0) +
              (fnbPrice?.total_price || 0) -
              (discount ? discount : 0)
            )
            && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t("Cash Card")}{" "}{t("Redeem")}:{" "}</div>
                  <div className="right_value"> {" "}
                    {curr_code} {currencyFormatter(cashCardRedemptionSelection?.amount)}</div>
                </section>
              </div>
            )
          }
              
              <div className="payment_details_rows payment_details_total">
                 <section className="">
                <h5>{t("Grand Total")}
                
              </h5>
              <h4 className="">
                  {curr_code}{" "}
                {currencyFormatter(
                  reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0)
                  > 0
                    ? reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0)
                    : 0
                )}
                </h4>



            </section>
            <h6>
                {t("(Incl. of 2.5% VAT - ")} {curr_code}{" "}
            {currencyFormatter(
              _.round(
                percentage(
                  2.5,
                  reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0) - (cashCardRedemptionSelection?.amount ?? 0)
                  > 0
                    ? reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0) - (cashCardRedemptionSelection?.amount ?? 0)
                    : 0
                ),
                4
              )
            )}{" "}
                  {")"}
          </h6>
              </div>

        </div>
          </> :
        // DESKTOP DESIGN
        <>
           <article className="movie_info" ref={movieInfoRef}>
         <div className="movie-img">
            <img
              src={
                getLangSpecificAttribute(
                  scheduleDetails?.languageSpecificImages,
                  lang_id,
                  "artwork"
                ) ||
                scheduleDetails?.md_thumbnail_url ||
                dummyPoster
              }
              alt="Movie Img"
            />
            </div>

            <div className="">
              {/* <h6 className="movie_booking_type">{
                category_id == 1 ? t('On-demand').toUpperCase() : t("Now Showing").toUpperCase()
              }</h6> */}
            <h1 className="movie_info_title">
              {getLangSpecificAttribute(
                scheduleDetails?.altMovieContent,
                lang_id,
                "mc_title"
              ) || scheduleDetails?.movie_title}
            </h1>
            {/* <ul className="list-inline movie_info_props">
              <li  className="list-inline-item">
                  <article>
                    <h6 className="">{t("Genre")}</h6>
                <h5 className="">{scheduleDetails?.movie_genres
                  ?.filter((genre) => genre[iso_2?.toLowerCase()])
                  ?.map(
                    (genre, index) =>
                      genre[iso_2?.toLowerCase()] &&
                      index < 13 && (
                        <span key={index}>
                          {genre[iso_2?.toLowerCase()]}
                          {index !== scheduleDetails?.movie_genres?.length - 1 ? ', ' : ''}
                        </span>
                      )
                  )}</h5>
                </article>
              </li>
              <li className="list-inline-item">
                  <article>
                    <h6 className="">{t("Year")}</h6>
                <h5>{moment(
                  scheduleDetails?.original_mrrdr_release_date ===
                    `Invalid Date`
                    ? moment()
                    : scheduleDetails?.original_mrrdr_release_date ||
                    moment()
                ).format("YYYY")}</h5>
                </article>
              </li>
              <li className="list-inline-item">
                  <article>
                    <h6 className="">{t('Duration')}</h6>
                <h5>{scheduleDetails?.mrrdr_runtime} {t("common.mins")}</h5>
                </article>
              </li>
            </ul> */}
            <div className="movie_info_rating_n_format">
              <span className="movie_info_rating">{scheduleDetails?.rating}</span>
              <div className="movie_info_language"> {
                scheduleDetails?.lang_translation_obj?.[iso_2?.toLowerCase()]
                ?? !!t(scheduleDetails?.lang_name) ? t(scheduleDetails?.lang_name) : scheduleDetails?.lang_name}{`${
                scheduleDetails?.sub_1_iso_2 ? `/${scheduleDetails?.sub_1_iso_2}` : ''
              }${
                scheduleDetails?.sub_1_iso_2 && scheduleDetails?.sub_2_iso_2 ? `-${scheduleDetails?.sub_2_iso_2}` : ''
              }`}
              {" "}</div>
                <div className="movie_info_language">
                  {scheduleDetails?.mrrdr_runtime} {t("common.mins")}
                </div>
            </div>
          </div>
        <div className="checkout_title_n_value">
          <h6 className="checkout_cinema_title">{t("Date")} &amp; {t("Time")}</h6>
          <p>
            {moment(scheduleDetails?.ss_actual_start_date_time).format(
              "ll"
            )}{" "}
            |{" "}
            {moment(scheduleDetails?.ss_start_show_time, "HH:mm:ss").format(
              "HH:mm"
            )}
          </p>
        </div>
        <div className="checkout_title_n_value">
              <h6 className="checkout_cinema_title">{t("Cinema")}</h6>
            <p>{scheduleDetails?.screen_name}</p>
          <p> {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}</p>
        </div>
        <div className="checkout_title_n_value">
          <h6 className="checkout_cinema_title">{t('Booking Summary').toUpperCase()}</h6>
          <article className="payment_details_rows">
              {seatTypes?.length > 0 && seatTypes?.map((seatType, seatTypeIndex) => (
                <>

                  {seatType?.seats?.length > 0 && seatType?.seats?.map((rcg_child, index2) => (
                    <section>
                      <p className="left_value">
                        <span>
                          (x{rcg_child.no_of_seats} {t("Seats")})
                        </span>&nbsp;
                        <>
                    {seatType.sst_seat_type}
                  </>
                      <span className="">{rcg_child?.ticket_type_translations?.[
                        iso_2?.toLowerCase()
                      ] || rcg_child.tt_name}</span>
                      </p>
                      <p className="right_value">{curr_code}{" "}{currencyFormatter(rcg_child?.total_price)}</p>
                    </section>
                  ))}
                </>

              ))}
            {scheduleDetails?.booking_type_id == 3 ? (
              <>
                <section className="">
                  <div className="left_value">{scheduleDetails?.screen_name}</div>
                  <div className="right_value">{!!ticket_count
                    ? `(x${ticket_count}):`
                    : `(x${scheduleDetails?.ticket_count}):`}</div>
                </section>
                <section className="">
                  <div className="left_value">{scheduleDetails?.booking_type_name}</div>
                  <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                </section>
              </>
            ) : (
                    <>
                    {/* <section className="">
                <div className="left_value">{scheduleDetails?.seat_name}</div>
                <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                      </section> */}
                    </>
            )}

          </article>
          {/* {selectedFnbItems && selectedFnbItems.length > 0 && (
            <article className="grey-title-lg mb-2">
              {t("Concessions")}
            </article>
          )} */}

         {selectedFnbItems &&
              selectedFnbItems.length > 0 && <div className="payment_details_rows">
              {selectedFnbItems.map((item) => (
                <section>
                  <div className="left_value">{getLangSpecificAttribute(
                    item?.FnbContent,
                    lang_id,
                    "item_lang_name"
                  ) || item.item_name}
                    {item.Quantity && <span>(x{item.Quantity})</span>}</div>
                  <div className="right_value">{curr_code}{" "}
                    {currencyFormatter(item.fp_total_amount)}</div>
                </section>))}
          </div>}

          {
            discount && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t("Discount")}:{" "}</div>
                  <div className="right_value"> {" "}
                    - {curr_code} {currencyFormatter(discount)}</div>
                </section>
              </div>
            )
          }
          {
            !discount &&
            !!cashCardRedemptionSelection?.amount &&
            cashCardRedemptionSelection?.amount <= (
              (reservationDetails?.amount || 0) +
              (fnbPrice?.total_price || 0) -
              (discount ? discount : 0)
            )
            && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t("Cash Card")}{" "}{t("Redeem")}:{" "}</div>
                  <div className="right_value"> {" "}
                    {curr_code} {currencyFormatter(cashCardRedemptionSelection?.amount)}</div>
                </section>
              </div>
            )
          }

            </div>
            <div className="movie_info_scroll_space"/>
      </article>
          <article className="movie-bottom-checkout" ref={movieBottomRef}>
        <section className="">
              <div className="checkout_space">
                <span className="chek_text" style={{fontSize:"16px",fontWeight:"400"}}>{t("Grand Total")}

              </span>
              <span className="chek_text">
                  {curr_code}{" "}
                {currencyFormatter(
                  reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0)
                  > 0
                    ? reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0)
                    : 0
                )}
                </span>
                
              </div>
              <div className="check_span" style={{marginTop:"6px"}}>
                {t("(Incl. of 2.5% VAT - ")} {curr_code}{" "}
            {currencyFormatter(
              _.round(
                percentage(
                  2.5,
                  reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0) - (cashCardRedemptionSelection?.amount ?? 0)
                  > 0
                    ? reservationDetails?.amount + fnbPrice.total_price - (discount ? discount : 0) - (cashCardRedemptionSelection?.amount ?? 0)
                    : 0
                ),
                4
              )
            )}{" "}
                  {")"}
          </div>
              
            </section>
            
        {/* <div className="img-top">
          <img
            src={circles}
            alt="Arrow Down"
          />
        </div> */}
          </article>
          </>
      }
    </div>
  );
};
//End Payment Booking Details

const PaymentFlow = ({ t }) => {
  const stripePublisherKeyFromStore = useSelector(
    (state) => state.movies.stripePublisherKey
  );
  const stripePublisherKey =
    localStorage.getItem("stripe_publisher_key") || stripePublisherKeyFromStore;
  const stripePromise = loadStripe(stripePublisherKey);
  return (
    <Elements stripe={stripePromise}>
      <PaymentFlowApp t={t} />
    </Elements>
  );
};
export default withNamespaces()(PaymentFlow);
